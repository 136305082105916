export const measurementsInitialData = {
  gender: "",
  length: "",
  width: "",
};

export function measurementsReducer(state, action) {
  switch (action.type) {
    case "SET_MEASUREMENTS":
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
