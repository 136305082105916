import React, { FormEvent } from "react";
import classnames from "classnames";
import {
  Box,
  FormControl,
  makeStyles,
  Grid,
  Card,
  CardContent,
  Hidden,
  NativeSelect,
  Typography,
  withStyles,
} from "@material-ui/core";

import { Store } from "../../store";

import hardCodedDefinitions from "../../data/definitions";

import BootstrapInput from "../../components/BootstrapInput";
import MFPButton from "../../components/Button";
import Hr from "../../components/Hr";
import Modal from "../../components/Modal";
import AttributeDefinitionsModal from "../../modals/AttributeDefinitions";

const sortByOptions = [
  {
    id: "brand_asc",
    label1: "Brand:",
    label2: "A to Z",
  },
  {
    id: "brand_desc",
    label1: "Brand:",
    label2: "Z to A",
  },
  {
    id: "price_asc",
    label1: "Price:",
    label2: "Low to High",
  },
  {
    id: "price_desc",
    label1: "Price:",
    label2: "High to Low",
  },
  {
    id: "model_asc",
    label1: "Model:",
    label2: "A to Z",
  },
  {
    id: "model_desc",
    label1: "Model:",
    label2: "Z to A",
  },
];

const useStyles = makeStyles((theme) => ({
  filtersBox: {
    border: "none",
    boxShadow: "none",
    [theme.breakpoints.between("sm", "md")]: {
      border: "1px solid #e8e8e8",
      borderRadius: theme.spacing(0),
      padding: 0,
    },

    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(1),
    },
  },
  // lgCols: {
  //   [theme.breakpoints.up("lg")]: {
  //     columns: 4,
  //     columnGap: theme.spacing(6),
  //   },
  // },
  attributes: {
    [theme.breakpoints.up("sm")]: {
      borderBottomWidth: 0,
      marginBottom: 0,
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
    },
    [theme.breakpoints.up("lg")]: {
      borderRadius: "3px",
      borderWidth: 0,
      margin: "0 auto",
      maxWidth: "540px",
      overflow: "visible",
      padding: "8px 16px",
      "& .MuiCardContent-root": {
        padding: 0,
        "& button": {
          backgroundColor: "transparent",
          borderRadius: 0,
          borderWidth: 0,
          color: "#333",
          fontSize: "0.75rem",
          fontWeight: 600,
          padding: "0",
          margin: "0",
          minWidth: 0,
          "&:hover": {
            backgroundColor: "transparent",
            color: "#0066C0",
          },
          "&.active": {
            color: "#0066c0",
            fontWeight: 700,
          },
        },
      },
    },
  },
  attributeOptionCol: {
    [theme.breakpoints.up("lg")]: {
      textAlign: "left",
    },
  },
  viewAllCol: {
    textAlign: "left",
    [theme.breakpoints.up("lg")]: {
      position: "absolute",
      left: "-120px",
    },
  },
  attributeOptions: {
    [theme.breakpoints.between("sm", "md")]: {
      borderTopWidth: 0,
      borderTopRightRadius: 0,
      borderTopLeftRadius: 0,
    },
    [theme.breakpoints.up("lg")]: {
      width: "540px",
      "& .attributeOptionBtn": {
        margin: "0 4px 4px",
        padding: "3px",
        width: "calc(100% - 8px)",
      },
      "& > .MuiCardContent-root": {
        paddingBottom: 0,
      },
    },
  },
  attributeOptionsGrid: {
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      margin: "0 auto",
      maxWidth: "480px",
    },
  },
  attributeOptionsHeader: {
    color: "#000",
    [theme.breakpoints.up("lg")]: {
      display: "inline-block",
      fontWeight: "bold",
    },
  },
  viewAllShoesTabletBtn: {
    borderWidth: 0,
    fontSize: "0.625rem",
    fontWeight: 500,
    position: "absolute",
    left: "4px",
    top: "3px",
  },
  definitionsLink: {
    borderWidth: 0,
    fontSize: "0.625rem",
    fontWeight: 500,
    [theme.breakpoints.down("md")]: {
      position: "absolute",
      right: "4px",
      top: "3px",
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: "6px !important",
    },

    "&:hover": {
      backgroundColor: "transparent",
    },
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  button: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(0.5),
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
      width: `calc(100% - ${theme.spacing(1)}px)`,
    },
  },
  multiselect: {
    fontSize: "0.5625rem",
  },
  sortByContainer: {
    marginBottom: 0,
    [theme.breakpoints.up("lg")]: {
      borderRadius: "3px",
      display: "inline-block",
      "& .MuiCardContent-root": {
        padding: "8px",
      },
    },
  },
  sortByHeader: {
    [theme.breakpoints.up("lg")]: {
      marginBottom: "1rem",
      marginTop: "4px",
    },
  },

  selectOrClear: {
    [theme.breakpoints.down("md")]: {
      left: "4px",
      position: "absolute",
      top: "6px",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: theme.spacing(0.5),
    },
    "& button": {
      borderWidth: 0,
      fontSize: "0.625rem",
      fontWeight: 500,
      padding: 0,
      textTransform: "none",
      textAlign: "left",
      minWidth: "50px",
      "&:hover": {
        backgroundColor: "transparent",
      },
      "&:focus": {
        backgroundColor: "transparent",
      },
    },
  },

  filtersTitle: {
    [theme.breakpoints.up("lg")]: {
      display: "inline-block",
      marginLeft: "50px",
      verticalAlign: "baseline",
    },
  },
}));

const StyledCardContent = withStyles((theme) => ({
  root: {
    [theme.breakpoints.only("xs")]: {
      padding: theme.spacing(0.5),
      "&:last-child": {
        paddingBottom: "inherit",
      },
    },
    [theme.breakpoints.up("sm")]: {
      paddingBottom: theme.spacing(2),
    },
    "& select": {
      lineHeight: "normal",
      height: "16px",
      paddingTop: "7px",
      paddingBottom: "7px",
    },
  },
}))(CardContent);

const HomeFilters: React.SFC = () => {
  const { state, dispatch } = React.useContext(Store);
  const {
    home: { sortBy, orderBy, selectedAttribute, selectedAttributeOptions },
  } = state;
  const selectedSort = `${sortBy}_${orderBy}`;
  const definitions = state.dictionary;
  const classes = useStyles();
  const [definitionsOpen, setDefinitionsOpen] = React.useState(false);

  const clearFilters = () => {
    dispatch({
      type: "CLEAR_FILTERS",
    });
  };

  const handleClearOptions = () => {
    return dispatch({
      type: "CLEAR_SELECTED_ATTRIBUTE_OPTIONS",
    });
  };

  const handleSortClick = (buttonId: string) => {
    const attributes = buttonId.split("_");

    dispatch({
      type: "SET_SORT_BY",
      payload: attributes[0],
    });

    dispatch({
      type: "SET_ORDER_BY",
      payload: attributes[1],
    });
  };

  function handleSortChange(event: FormEvent<HTMLSelectElement>) {
    handleSortClick(event.currentTarget.value);
  }

  const renderAttributes = () => {
    const attributeNames = Object.keys(hardCodedDefinitions);
    return attributeNames.map((attributeName, index) => {
      const isSelected = selectedAttributeOptions
        .map((selected: string) => selected.split("_")[0])
        .includes(attributeName);

      return (
        <React.Fragment key={index}>
          <Grid item xs={4} sm={3} className={classes.attributeOptionCol}>
            <MFPButton
              active={selectedAttribute === attributeName}
              className={classnames(classes.button)}
              key={index}
              onClick={() => {
                dispatch({
                  type: "SET_SELECTED_ATTRIBUTE",
                  payload: attributeName,
                });
              }}
              selected={isSelected}
            >
              <Hidden smUp>
                {hardCodedDefinitions[attributeName].label.xs}
              </Hidden>
              <Hidden only="xs">
                {hardCodedDefinitions[attributeName].label.default}
              </Hidden>
            </MFPButton>
          </Grid>
        </React.Fragment>
      );
    });
  };

  const getSelectedAttributeOptions = () =>
    definitions[selectedAttribute].options.map(
      (option: { label: string; code: string }) => {
        const attributeOptionId = `${selectedAttribute}_${option.code}`;
        const isSelected = selectedAttributeOptions.includes(attributeOptionId);

        return (
          <Grid item xs={4} md={3} lg={4} key={attributeOptionId}>
            <MFPButton
              selected={isSelected}
              className={classnames(classes.button, "attributeOptionBtn")}
              onClick={() => {
                dispatch({
                  type: "SET_SELECTED_ATTRIBUTE_OPTIONS",
                  payload: `${selectedAttribute}_${option.code}`,
                });
              }}
            >
              {option.label}
            </MFPButton>
          </Grid>
        );
      }
    );

  return (
    <>
      <Card className={classnames(classes.filtersBox, classes.attributes)}>
        <StyledCardContent>
          <Box textAlign="center" style={{ position: "relative" }}>
            <Box className={classes.filtersTitle}>
              <Hidden only={["xs", "lg", "xl"]}>
                <MFPButton
                  className={classnames("faded", classes.viewAllShoesTabletBtn)}
                  size="small"
                  color="default"
                  onClick={clearFilters}
                >
                  View All Shoes
                </MFPButton>
              </Hidden>

              <Typography
                variant="overline"
                className={classes.attributeOptionsHeader}
              >
                Filters
              </Typography>

              <MFPButton
                className={classnames("faded", classes.definitionsLink)}
                size="small"
                color="default"
                onClick={() => setDefinitionsOpen(true)}
              >
                definitions
              </MFPButton>
            </Box>
            <Grid container>
              <Hidden only={["sm", "md"]}>
                <Grid item xs={4} sm="auto" className={classes.viewAllCol}>
                  <MFPButton
                    selected={selectedAttribute === "View All"}
                    className={classes.button}
                    onClick={clearFilters}
                  >
                    View All Shoes
                  </MFPButton>
                </Grid>
              </Hidden>

              {renderAttributes()}
            </Grid>
          </Box>
        </StyledCardContent>
      </Card>

      {definitions && Object.keys(definitions).length > 0 && (
        <Grid container spacing={0} justify="center">
          <Grid item xs={12} lg="auto">
            <Card
              className={`${classes.filtersBox} ${classes.attributeOptions}`}
            >
              <StyledCardContent>
                <Box textAlign="center" style={{ position: "relative" }}>
                  <Typography
                    variant="overline"
                    className={classes.attributeOptionsHeader}
                  >
                    {selectedAttribute && selectedAttribute !== "View All"
                      ? definitions[selectedAttribute].label.default
                      : "Viewing All Climbing Shoes"}
                  </Typography>

                  {selectedAttribute !== "View All" && (
                    <>
                      <Grid
                        container
                        spacing={0}
                        className={classes.attributeOptionsGrid}
                      >
                        {selectedAttribute && getSelectedAttributeOptions()}
                      </Grid>

                      <Box className={classes.selectOrClear}>
                        <MFPButton
                          className="faded"
                          size="small"
                          color="default"
                          onClick={handleClearOptions}
                        >
                          Clear Options
                        </MFPButton>
                      </Box>
                    </>
                  )}
                </Box>
              </StyledCardContent>
            </Card>
          </Grid>

          <Hidden mdDown>
            <Grid item xs={12}>
              <Box mb={1}>
                <Hr />
              </Box>
            </Grid>
          </Hidden>

          <Grid item xs={12} style={{ textAlign: "right" }}>
            <Card
              className={`${classes.filtersBox} ${classes.sortByContainer}`}
            >
              <StyledCardContent>
                <Box textAlign="center">
                  <Hidden lgUp>
                    <Typography
                      variant="overline"
                      component="h6"
                      className={classes.sortByHeader}
                    >
                      Sort Results By
                    </Typography>
                  </Hidden>
                  <Grid container>
                    <Hidden lgUp>
                      {sortByOptions.map((option, index) => (
                        <React.Fragment key={index}>
                          <Grid xs={6} sm={2} lg="auto" item>
                            <MFPButton
                              selected={selectedSort === option.id}
                              className={` ${classes.button}`}
                              key={option.id}
                              onClick={() => handleSortClick(option.id)}
                            >
                              {option.label1}
                              <Box component="span" ml={0.25}>
                                {option.label2}
                              </Box>
                            </MFPButton>
                          </Grid>
                        </React.Fragment>
                      ))}
                    </Hidden>

                    <Hidden mdDown>
                      <Box textAlign="right">
                        <Box component="span" mr={1}>
                          <Typography
                            variant="overline"
                            component="span"
                            style={{
                              display: "inline-block",
                            }}
                          >
                            Sort By:
                          </Typography>
                        </Box>
                        <FormControl>
                          <NativeSelect
                            name="sort"
                            value={selectedSort}
                            onChange={handleSortChange}
                            input={<BootstrapInput name="sort" />}
                          >
                            {sortByOptions.map((option) => (
                              <option value={option.id} key={option.id}>
                                {option.label1} {option.label2}
                              </option>
                            ))}
                          </NativeSelect>
                        </FormControl>
                      </Box>
                    </Hidden>
                  </Grid>
                </Box>
              </StyledCardContent>
            </Card>
          </Grid>
        </Grid>
      )}

      <Modal
        open={definitionsOpen}
        handleClose={() => setDefinitionsOpen(false)}
      >
        <AttributeDefinitionsModal />
      </Modal>
    </>
  );
};

export default HomeFilters;
