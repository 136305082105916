import React from "react";
import classnames from "classnames";
import { Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  imageGallery: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  mainImage: {
    width: "50%",
    [theme.breakpoints.up("sm")]: {
      maxWidth: 240,
      width: "60%",
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: 400,
    },
  },
  thumbnails: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      cursor: "pointer",
      display: "block",
      maxWidth: "100%",
      width: "56px",
      [theme.breakpoints.between("sm", "md")]: {
        width: "80px",
      },
    },
    [theme.breakpoints.up("lg")]: {
      position: "absolute",
      left: "0",
      top: "0",
      flexDirection: "column",
    },
  },
  thumbnail: {
    [theme.breakpoints.up("lg")]: {
      border: "1px solid #e3e3e3",
      borderRadius: theme.spacing(0.625),
      "&:hover": {
        borderColor: "#0066c0",
      },
      "&.active": {
        borderColor: "#0066c0",
      },
    },
  },
}));

interface Props {
  mainImages: string[];
  previewImages: string[];
}

const ImageGallery: React.SFC<Props> = ({
  mainImages = [],
  previewImages = [],
}) => {
  const classes = useStyles();
  const [mainImage, setMainImage] = React.useState(mainImages[0]);

  React.useEffect(() => {
    setMainImage(mainImages[0]);
  }, [mainImages]);

  return (
    <Box textAlign="center" className={classes.imageGallery}>
      {mainImage && (
        <img src={mainImage} className={classes.mainImage} alt="" />
      )}
      <Box className={classes.thumbnails}>
        {previewImages.map((image, index) => {
          return (
            <Box
              mb={0.75}
              p={0.5}
              key={image}
              className={classnames(classes.thumbnail, {
                active:
                  image &&
                  mainImage &&
                  image.split("-")[0] === mainImage.split("-")[0],
              })}
            >
              <img
                src={image}
                alt=""
                onClick={() => setMainImage(mainImages[index])}
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default ImageGallery;
