import React from "react";
import { Box, Container, Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Brands from "../../components/Brands";
import HomeFilters from "./Filters";
import MobileHeader from "./MobileHeader";
import MFPButton from "../../components/Button";

import { Product } from "../../types/product";

const ProductList = React.lazy(() => import("./ProductList"));

const useStyles = makeStyles((theme) => ({
  homePage: {
    "& .navbar": {
      dispay: "block",
      [theme.breakpoints.only("xs")]: {
        backgroundColor: "#242B38",
      },
    },
  },
  filtersWrapper: {
    maxWidth: "1040px",
    padding: 0,
    [theme.breakpoints.between("sm", "md")]: {
      backgroundColor: "#f9f9f9",
      padding: theme.spacing(1, 1, 1.25),
    },
  },
  moreResultsWrapper: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(6),
    },
  },
}));

interface Props {
  currentPage: number;
  loading: boolean;
  loadMore: () => void;
  products: Product[];
  showLoadMore: boolean;
}

const Home: React.FC<Props> = ({
  currentPage = 1,
  loading = false,
  loadMore = () => null,
  products = [],
  showLoadMore = false,
}) => {
  const classes = useStyles();

  const displayResults = () => {
    if (currentPage === 1 && loading) {
      return "Loading...";
    }

    if (products.length === 0) {
      return "No results match current filters.";
    }

    return <ProductList products={products} />;
  };

  return (
    <div className={classes.homePage}>
      <Hidden smUp>
        <MobileHeader />
      </Hidden>

      <Hidden smDown>
        <Brands />
      </Hidden>

      <Container className={classes.filtersWrapper}>
        <HomeFilters />
      </Container>

      <Container>
        <Box mt={4}>
          <React.Suspense fallback={<div>Loading...</div>}>
            {displayResults()}

            <Box textAlign="center" className={classes.moreResultsWrapper}>
              {currentPage > 1 && loading && "Loading..."}
              {!loading && showLoadMore && (
                <MFPButton onClick={loadMore} style={{ width: 120 }}>
                  More Results
                </MFPButton>
              )}
            </Box>
          </React.Suspense>
        </Box>
      </Container>
    </div>
  );
};
export default Home;
