import React from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";

import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { Store } from "../store";

const useStyles = makeStyles((theme) => ({
  currentMeasurement: {
    textAlign: "center",
    [theme.breakpoints.only("xs")]: {
      fontSize: "0.8rem",
      paddingTop: theme.spacing(1),
    },
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      alignItems: "center",
    },
  },
  measurementBox: {
    fontSize: "0.6875rem",
    position: "relative",
    padding: theme.spacing(0.25, 1),
  },
  value: {
    display: "block",
    "&::first-letter": {
      textTransform: "capitalize",
    },
  },
  measurementGridItem: {
    "&:first-child span": {
      display: "none",
    },
  },
  headerBtn: {
    backgroundColor: "#ffc000",
    borderRadius: theme.spacing(0.5),
    boxShadow: "none",
    fontSize: "0.6875rem",
    letterSpacing: "1px",
    minWidth: "100px",
    padding: theme.spacing(0.5, 1),
    textTransform: "none",
    [theme.breakpoints.only("xs")]: {
      borderRadius: theme.spacing(4),
      margin: theme.spacing(1, 0),
      textDecoration: "none",
      width: "120px",
    },
    [theme.breakpoints.up("lg")]: {
      "& .MuiButton-label": {
        display: "inline-block",
        padding: "0 8px",
      },
    },
    "&:hover": {
      backgroundColor: "#ecb100",
      boxShadow: "none",
    },
  },
}));

const measurementLabels: { [key: string]: string } = {
  gender: "Gender",
  width: "Foot Width",
  length: "Foot Length",
};

const CurrentMeasurement: React.SFC = () => {
  const classes = useStyles();
  const { state } = React.useContext(Store);

  const renderValue = (key: string) => {
    const value = state.measurements[key];
    if (key === "length" && value) {
      return value + " cm";
    }

    return value || "-";
  };

  return (
    <Box className={`${classes.currentMeasurement} currentMeasurement`}>
      <Link to="/size-tool" style={{ textDecoration: "none" }}>
        <Button
          variant="contained"
          size="large"
          className={classnames(classes.headerBtn, "sizeToolBtn")}
        >
          Size Tool
        </Button>
      </Link>

      <Link
        to="/size-tool"
        style={{ color: "inherit", textDecoration: "none" }}
      >
        <Box textAlign="center" ml={1}>
          <Typography variant="body1">
            <b>Saved Foot Size</b>
          </Typography>
          <Grid container justify="center">
            {Object.keys(measurementLabels).map((key) => {
              return (
                <Grid item key={key} className={classes.measurementGridItem}>
                  <Box textAlign="center" className={classes.measurementBox}>
                    <Box pb={0.25}>{measurementLabels[key]}</Box>
                    <b className={classes.value}>{renderValue(key)}</b>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Link>
    </Box>
  );
};

const useStyles2 = makeStyles((theme) => ({
  currentMeasurementWrapper: {
    borderTop: "1px solid #cbcbcb",
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(12),
    padding: theme.spacing(0, 0, 1.25),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2),
    },
    "& .sizeToolBtn": {
      display: "none",
    },
  },
}));

export const CurrentMeasurementPageBottom = () => {
  const classes = useStyles2();

  return (
    <Box className={classes.currentMeasurementWrapper}>
      <CurrentMeasurement />
    </Box>
  );
};

export default CurrentMeasurement;
