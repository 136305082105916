import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Hidden } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import ReactGA from "react-ga";

import { Store } from "./store";
import API from "./api";

import Home from "./pages/Home";
import Search from "./pages/Search";
import SizeTool from "./pages/SizeTool";
import Shoe from "./pages/Shoe";
import ScrollToTop from "./components/ScrollToTop";
import Masthead from "./components/Masthead";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

import RouteTracker from "./GATracker";

import theme from "./theme";

import "./App.css";

const api = new API();

const App = () => {
  const isProd = process.env.NODE_ENV === "production";
  if (
    isProd &&
    window.location.hostname &&
    window.location.hostname.indexOf("mountainfootwearproject.com") === 0
  ) {
    ReactGA.initialize("UA-168751583-1");
  }

  const { dispatch } = React.useContext(Store);

  const fetchDictionary = async () => {
    const response = await api.getDictionary();

    if (!response.message) {
      return dispatch({
        type: "SET_DICTIONARY",
        payload: response,
      });
    }
  };

  React.useEffect(() => {
    fetchDictionary();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        {isProd && <RouteTracker />}
        <ScrollToTop>
          <div className="App">
            <div className="content">
              <Masthead />
              <Hidden only={"xs"}>
                <Navbar />
              </Hidden>

              <Switch>
                <Route path="/" exact>
                  <Home />
                </Route>
                <Route path="/size-tool" exact>
                  <SizeTool />
                </Route>
                <Route path="/search" exact>
                  <Search />
                </Route>
                <Route path="/:id">
                  <Shoe />
                </Route>
              </Switch>
            </div>
            <Footer />
          </div>
        </ScrollToTop>
      </Router>
    </ThemeProvider>
  );
};

export default App;
