import React, { FormEvent } from "react";
import { useHistory } from "react-router-dom";
import { Button, InputBase, makeStyles } from "@material-ui/core";

import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    [theme.breakpoints.up("sm")]: {
      borderRadius: "3px",
      overflow: "hidden",
      maxWidth: "220px",
      paddingRight: theme.spacing(7),
    },
    "& .MuiSvgIcon-root": {
      width: "0.8em",
    },
  },
  searchIcon: {
    alignItems: "center",
    borderRadius: 0,
    color: "#888",
    cursor: "pointer",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    minWidth: 0,
    padding: 0,
    position: "absolute",
    right: 0,
    top: 0,
    width: theme.spacing(4),
    zIndex: 100,
    [theme.breakpoints.only("xs")]: {
      display: "none",
    },
  },
  inputRoot: {
    color: "white",
    width: "100%",
  },
  inputInput: {
    fontSize: "0.6875rem",
    padding: theme.spacing(1.25),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 220,
      padding: theme.spacing(1, 2),
    },
  },
}));

interface Props {
  defaultValue?: string;
}

const SearchInput: React.SFC<Props> = ({ defaultValue = "" }) => {
  const classes = useStyles();
  const history = useHistory();
  const [value, setValue] = React.useState(defaultValue);

  const handleSubmit = (event: FormEvent) => {
    if (value) {
      history.push(`/search?query=${value}`);
    }
    event.preventDefault();
  };

  return (
    <form
      onSubmit={handleSubmit}
      className={`${classes.search} searchInput`}
      noValidate
      id="search-input-form"
    >
      <InputBase
        placeholder="Search for a brand or model"
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ "aria-label": "Search" }}
        value={value}
        onChange={(event) => setValue(event.target.value)}
      />
      <Button
        className={`${classes.searchIcon} searchIcon`}
        onClick={(event) => handleSubmit(event)}
      >
        <SearchIcon />
      </Button>
    </form>
  );
};

export default SearchInput;
