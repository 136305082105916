import React from "react";
import { Box, Grid, Hidden, makeStyles, ButtonBase } from "@material-ui/core";


import { Store } from "../../../store";
import API from "../../../api";
import { Product } from "../../../types/product";

import Fieldset from "../../../components/Fieldset";
import SaveButton from "../SaveButton";

import Column from "../Column";
import ColumnButton from "../ColumnButton";

import { fits } from "../../../data/fits";
import Modal from "../../../components/Modal";

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import AttributeDefinitionsModal from "../../../modals/AttributeDefinitions";

const api = new API();

const CurrentShoe: React.SFC<{
  handleSave: (paramString: string) => void;
}> = ({ handleSave }) => {
  const { dispatch, state } = React.useContext(Store);
  const { currentShoe, dictionary } = state;
  const [unsavedState, setUnsavedState] = React.useState(currentShoe);
  const [definitionsOpen, setDefinitionsOpen] = React.useState(false);

  const [products, setProducts] = React.useState([]);
  const [product, setProduct] = React.useState({
    availableSizes: [],
    gender: "",
    model: "",
    region: "",
  });

  const onSave = () => {
    dispatch({
      type: "SET_CURRENT_SHOE",
      payload: unsavedState,
    });
    const { fit, model, size } = unsavedState;
    if (fit && model && size) {
      handleSave(`id=${model}&fit=${fit}&size=${size}`);
    }
  };

  const fetchProductsAction = async () => {
    let url = `?limit=1000000&includeDiscontinued=true`;
    const response = await api.getProducts(url);

    if (response.message) {
      setProducts([]);
    } else {
      setProducts(response);
    }
  };

  React.useEffect(() => {
    fetchProductsAction();
  }, []);

  React.useEffect(() => {
    fetchProductAction();
  }, [unsavedState.model]);

  const fetchProductAction = async () => {
    const response = await api.getProduct({ id: unsavedState.model });

    if (response.message) {
      setProduct({ availableSizes: [], gender: "", model: "", region: "" });
    } else {
      setProduct(response);

      if (!response.availableSizes.includes(unsavedState.size)) {
        setUnsavedState((prevState: any) => ({
          ...prevState,
          size: response.availableSizes[0],
        }));
      }
    }
  };

  let brands =
    (dictionary.brand &&
      dictionary.brand.options.map(
        (brand: { label: string }) => brand.label
      )) ||
    [];

  let models = products.filter(
    (product: Product) => product.brand === unsavedState.brand
  );

  const Measurements = () => (
    <>
      {unsavedState.brand}
      <Box component="span" px={1}>
        &middot;
      </Box>
      {product.model} {product.gender}
      <Box component="span" px={1}>
        &middot;
      </Box>
      {unsavedState.size} {product.region === "US" ? "U.S." : "Euro"}
      <Box component="span" px={1}>
        &middot;
      </Box>
      {fits[unsavedState.fit].label}
    </>
  );

  return (
    <>
      <Box pb={2} textAlign="center">
        <SaveButton title={<>Save Shoe &amp; Exit</>} onClick={onSave} />
      </Box>

      <Fieldset maxWidth={800} legend={<Measurements />}>
        <Grid container spacing={2} justify="center">
          <Column
            title={
              <>
                Brand <Hidden smUp>(step 1/4)</Hidden>
              </>
            }
          >
            {brands.map((brand: string) => (
              <ColumnButton
                key={brand}
                selected={unsavedState.brand === brand}
                onClick={() => {
                  const brandData: { id: string } = products.find(
                    (item: Product) => item.brand === brand
                  ) || { id: "" };
                  console.log("brandData", brandData);
                  const brandDefaultModel = brandData && brandData.id;
                  setUnsavedState((prevState: any) => ({
                    ...prevState,
                    brand,
                    model: brandDefaultModel,
                  }));
                }}
              >
                {brand}
              </ColumnButton>
            ))}
          </Column>

          <Column
            title={
              <>
                Model <Hidden smUp>(step 2/4)</Hidden>
              </>
            }
          >
            {models.map((model: Product) => (
              <ColumnButton
                key={model.id}
                selected={unsavedState.model === model.id}
                onClick={() =>
                  setUnsavedState((prevState: any) => ({
                    ...prevState,
                    model: model.id,
                  }))
                }
              >
                {model.model} - {model.gender}
              </ColumnButton>
            ))}
          </Column>

          <Column
            title={
              <>
                Size - {product.region === "US" ? "U.S." : "Euro"}{" "}
                <Hidden smUp>(step 3/4)</Hidden>
              </>
            }
          >
            {product.availableSizes &&
              product.availableSizes
                .filter((size) => {
                  if(product.gender === 'Kids') {
                    return size % 1 === 0
                  }
                  return true
                })
                .map((size) => (
                  <ColumnButton
                    key={size}
                    selected={unsavedState.size === size}
                    onClick={() =>
                      setUnsavedState((prevState: any) => ({
                        ...prevState,
                        size,
                      }))
                    }
                  >
                  {size}
                </ColumnButton>
              ))}
          </Column>

          <Column
            title={
              <>
                Fit <ButtonBase onClick={() => setDefinitionsOpen(true)} style={{paddingBottom: "1px", paddingRight: "3px"}}><InfoOutlinedIcon fontSize="inherit" /></ButtonBase><Hidden smUp>(step 4/4)</Hidden>
              </>
            }
          >
            {Object.entries(fits).map(([key, value]) => (
              <ColumnButton
                key={key}
                selected={unsavedState.fit === key}
                onClick={() =>
                  setUnsavedState((prevState: any) => ({
                    ...prevState,
                    fit: key,
                  }))
                }
              >
                {value.label}
              </ColumnButton>
            ))}
          </Column>
        </Grid>

        <Box mt={4} mb={1.5}>
          <Measurements />
        </Box>

        <SaveButton title={<>Save Shoe &amp; Exit</>} onClick={onSave} />
      </Fieldset>
      <Modal
        open={definitionsOpen}
        handleClose={() => setDefinitionsOpen(false)}
      >
        <AttributeDefinitionsModal />
      </Modal>
    </>
  );
};

export default CurrentShoe;
