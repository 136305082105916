import React from "react";
import { makeStyles } from "@material-ui/core";

import MFPButton from "../../components/Button";

const useStyles = makeStyles((theme) => ({
  saveButton: {
    borderColor: "black",
    minWidth: "120px",
  },
}));

const SaveButton: React.SFC<{
  fullWidth?: boolean;
  onClick?: () => void;
  size?: "medium" | "large" | "small";
  title: React.ReactNode;
}> = ({ fullWidth = false, onClick, size, title }) => {
  const classes = useStyles();

  return (
    <MFPButton
      className={classes.saveButton}
      onClick={onClick}
      size={size}
      fullWidth={fullWidth}
    >
      {title}
    </MFPButton>
  );
};

export default SaveButton;
