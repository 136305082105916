import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Grid, Hidden, Toolbar } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CurrentMeasurement from "../../components/CurrentMeasurement";
import LogoAsText from "../../components/LogoAsText";
import MFPButton from "../../components/Button";
import SearchInput from "../../components/SearchInput";

const useStyles = makeStyles((theme) => ({
  mobileHeader: {
    backgroundColor: "#242B38",
    flexGrow: 1,
    "& .searchInput": {
      backgroundColor: "white",
      borderRadius: 4,
      "& input": {
        color: "#242B38",
      },
      "& input::placeholder": {
        color: "#242B38",
      },
    },
    "& .currentMeasurement": {
      color: "white",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  searchBtns: {
    borderColor: "white",
    color: "white",
  },

  toolbar: {
    padding: theme.spacing(1),
    [theme.breakpoints.only("xs")]: {
      display: "block",
      textAlign: "center",
      minHeight: 120,
    },
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "space-between",
    },
  },
  toggleSearch: {
    color: "white",
    display: "none",
    [theme.breakpoints.only("xs")]: {
      cursor: "pointer",
      display: "block",
      position: "absolute",
      left: 0,
      padding: theme.spacing(1),
      top: 0,
      minWidth: 0,
      zIndex: 100,
    },
  },
}));

interface Props {
  canSearch?: boolean;
  children?: React.ReactNode;
}

const MobileHeader: React.SFC<Props> = ({ children = null }) => {
  const classes = useStyles();
  const [showSearch, setShowSearch] = React.useState(false);

  const getSearchBtn = () => (
    <>
      <Button
        className={classes.toggleSearch}
        onClick={() => setShowSearch((show) => !show)}
      >
        <SearchIcon />
      </Button>
    </>
  );

  return (
    <div className={classes.mobileHeader}>
      {getSearchBtn()}

      <Toolbar className={classes.toolbar}>
        <LogoAsText />

        <CurrentMeasurement />
      </Toolbar>

      <Hidden smUp>
        {showSearch ? (
          <Box pt={2} pr={3} pb={2} pl={2}>
            <Box pb={2}>
              <SearchInput />
            </Box>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <MFPButton
                  fullWidth
                  type="submit"
                  form="search-input-form"
                  className={classes.searchBtns}
                >
                  Search
                </MFPButton>
              </Grid>
              <Grid item xs={6}>
                <MFPButton
                  fullWidth
                  onClick={() => setShowSearch(false)}
                  className={classes.searchBtns}
                >
                  Cancel
                </MFPButton>
              </Grid>
            </Grid>
          </Box>
        ) : (
          children
        )}
      </Hidden>
      <Hidden only="xs">{children}</Hidden>
    </div>
  );
};

export default MobileHeader;
