import React from "react";
import { Link } from "react-router-dom";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import formatPrice from "../helpers/formatPrice";
import useWindowSize from "../hooks/useWindowSize";

const useStyles = makeStyles((theme) => ({
  itemImageHolder: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      display: "block",
      margin: "auto",
      maxHeight: "100%",
      maxWidth: "100%",
    },

    [theme.breakpoints.between("sm", "md")]: {
      padding: "7%",
    },

    [theme.breakpoints.up("lg")]: {
      minHeight: 240,
    },
  },
  brand: {
    color: theme.palette.grey[500],
    fontWeight: 600,

    lineHeight: 1.3,

    [theme.breakpoints.down("md")]: {
      fontSize: "0.6875rem",
    },
  },
  price: {
    fontSize: "0.875rem",
    lineHeight: 1.4,
  },
  model: {
    fontSize: "0.75rem",
    fontWeight: 600,
    lineHeight: 1.3,
    [theme.breakpoints.up("sm")]: {
      fontSize: "0.875rem",
    },
  },
  itemLink: {
    [theme.breakpoints.up("sm")]: {
      borderBottom: "1px solid #999",
    },
    paddingBottom: theme.spacing(2),
    color: "inherit",
    display: "block",
    margin: "0 auto",
    maxWidth: "240px",
    textDecoration: "none",
    [theme.breakpoints.up("lg")]: {
      "&:hover h6": {
        color: "#0066C0",
      },
    },
  },
  details: {
    lineHeight: 1.4,
    [theme.breakpoints.down("md")]: {
      fontSize: "0.6875rem",
    },
  },
}));

interface Props {
  asymmetricCurve: string;
  brand: string;
  climbingStyle: string[];
  gender: string;
  id: string;
  imgUrls: string[];
  smallImgUrls: string[];
  mediumImgUrls: string[];
  largeImgUrls: string[];
  model: string;
  price: string;
  profile: string;
}

const Shoe: React.SFC<Props> = ({
  asymmetricCurve,
  brand,
  climbingStyle,
  gender,
  id,
  imgUrls,
  smallImgUrls,
  mediumImgUrls,
  largeImgUrls,
  model,
  price,
  profile,
}) => {
  const windowSize = useWindowSize();
  const classes = useStyles();

  const getPhotoSize = (windowSize: string = "") => {
    if (mediumImgUrls && largeImgUrls) {
      switch (windowSize) {
        case "mobile":
          return mediumImgUrls[0];
        case "tablet":
          return mediumImgUrls[0];
        default:
          return largeImgUrls[0];
      }
    }

    return "";
  };

  return (
    <>
      <Link to={`/${id}`} className={classes.itemLink}>
        <Box mb={1} className={classes.itemImageHolder}>
          <img alt="" src={getPhotoSize(windowSize)} />
        </Box>
        <Typography component="p" variant="body1" className={classes.brand}>
          {brand}
        </Typography>
        <Typography variant="h6" className={classes.model}>
          {model} - {gender}
        </Typography>
        <Typography variant="body1" className={classes.details}>
          {climbingStyle.join(", ")}
          <br />
          {profile} Profile
          <br />
          {asymmetricCurve} Asymmetric Curve
        </Typography>
        <Typography className={classes.price}>
          <b>{formatPrice(price)}</b>
        </Typography>
      </Link>
    </>
  );
};

export default Shoe;
