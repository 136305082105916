import React from "react";
import { Box, Container, Grid, Hidden, Typography } from "@material-ui/core";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useLocation } from "react-router-dom";

import SearchInput from "../components/SearchInput";

import Shoe from "../components/Shoe";

import API from "../api";

const useStyles = makeStyles((theme) => ({
  searchInputWrapper: {
    borderBottom: "1px solid #e2e2e2",
    marginBottom: theme.spacing(3),
    padding: theme.spacing(1, 4),
    "& .searchInput": {
      border: "1px solid #151515",
      borderRadius: 4,
      height: 31,
      margin: "0 auto",
      maxWidth: "640px",
    },
    "& .MuiInputBase-input": {
      color: "#151515",
      width: "100%",
    },
    "& .searchIcon": {
      display: "flex",
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(6),
      },
    },
  },
  header: {
    position: "relative",
  },
  backLink: {
    color: "#000",
    position: "absolute",
    left: "5px",
    textDecoration: "none",
    top: "50%",
    transform: "translateY(-48%)",
    [theme.breakpoints.up("sm")]: {
      display: "inline-flex",
      alignItems: "center",
      left: "8px",
    },
    "&:hover $backLinkText": {
      textDecoration: "underline",
    },
  },
  backLinkText: {
    lineHeight: 1,
  },
  backLinkIcon: {
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.2rem",
    },
  },
}));

const useQuery = () => new URLSearchParams(useLocation().search);

interface SearchResult {
  asymmetricCurve: string;
  brand: string;
  climbingStyle: string[];
  toeShape: string[];
  gender: string;
  id: string;
  model: string;
  imgUrls: string[];
  smallImgUrls: string[];
  mediumImgUrls: string[];
  largeImgUrls: string[];
  price: string;
  profile: string;
}

const Search = () => {
  const classes = useStyles();
  const query = useQuery();
  const searchQuery = query.get("query") || "";
  const [searchResults, setSearchResults] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");

  React.useEffect(() => {
    const doSearch = async () => {
      setLoading(true);
      const api = new API();
      const response = await api.search(searchQuery);
      if (response.message) {
        setLoading(false);
        setError(response.message);
        setSearchResults([]);
      } else {
        setSearchResults(response);
        setLoading(false);
        setError("");
      }
    };
    doSearch();
  }, [searchQuery]);

  const displayResults = () => {
    if (!searchResults) {
      return "Try a search above.";
    }

    if (loading) {
      return "Loading...";
    }

    if (searchResults.length === 0) {
      return "No results";
    }

    return (
      <Grid container spacing={4} justify="center">
        {searchResults.map((product: SearchResult, index) => (
          <Grid item xs={6} sm={4} lg={3} key={index}>
            <Shoe {...product} />
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <>
      {" "}
      <Box className={classes.header} mb={4}>
        <Link to="/" className={classes.backLink}>
          <ChevronLeft className={classes.backLinkIcon} />
          <Hidden only="xs">
            <small className={classes.backLinkText}>Back to homepage</small>
          </Hidden>
        </Link>

        <Hidden only="xs">
          <Box py={1.75} textAlign="center">
            <Typography variant="h5" style={{ fontSize: "1.3rem" }}>
              Search Results
            </Typography>
          </Box>
        </Hidden>

        <Hidden smUp>
          <Box py={1} className={classes.searchInputWrapper}>
            <SearchInput defaultValue={searchQuery} />
          </Box>
        </Hidden>
      </Box>
      <Container>{displayResults()}</Container>
    </>
  );
};

export default Search;
