import React from "react";
import { Box, Grid, Typography, makeStyles } from "@material-ui/core";

import { Store } from "../store";

const useStyles = makeStyles((theme) => ({
  attributesWrapper: {
    [theme.breakpoints.up("md")]: {
      border: "1px solid #e0e0e0",
      borderBottom: "none",
      borderRight: "none",
    },
  },
  attributeBoxWrapper: {
    [theme.breakpoints.up("md")]: {
      borderRight: "1px solid #e0e0e0",
      borderBottom: "1px solid #e0e0e0",
    },
  },
  titleWrapper: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: '4em'
    }
  }
}));

const AttributeDefinitions = () => {
  const classes = useStyles();

  const { state } = React.useContext(Store);
  const definitions = state.dictionary;

  return (
    <>
      <Box textAlign="center" mb={3} py={0.625}>
        <Typography className={classes.titleWrapper} variant="h6">
          <b>Definitions</b>
        </Typography>
      </Box>
      <Grid container spacing={4} className={classes.attributesWrapper}>
        {Object.keys(definitions).map((key) => (
          <Grid
            item
            xs={12}
            md={6}
            lg={3}
            key={key}
            className={classes.attributeBoxWrapper}
          >
            <Box>
              <Box textAlign="center" mb={0.5}>
                <b style={{ fontSize: "0.75rem" }}>
                  {definitions[key].label.default}
                </b>
              </Box>
              <Typography
                variant="body1"
                style={{
                  color: "#0b0b0b",
                  fontWeight: 400,
                  whiteSpace: "pre-line",
                }}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: definitions[key].description,
                  }}
                />
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default AttributeDefinitions;
