import React, { Fragment } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import classnames from "classnames";
import {
  Box,
  Button,
  Container,
  Grid,
  Hidden,
  makeStyles,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@material-ui/core";
import ChevronLeft from "@material-ui/icons/ChevronLeft";

import { Store } from "../store";

import API from "../api";
import formatPrice from "../helpers/formatPrice";
import { fits } from "../data/fits";

import MFPButton from "../components/Button";
import Modal from "../components/Modal";
import useWindowSize from "../hooks/useWindowSize";

import ImageGallery from "../components/ImageGallery";
import AttributeDefinitionsModal from "../modals/AttributeDefinitions";
import Hr from "../components/Hr";

interface RouteParams {
  id: string;
}

const dataKeysTable1 = [
  { id: "footWidth", label: "Foot Width" },
  { id: "toeShape", label: "Toe Shape" },
];

const dataKeysTable2 = [
  { id: "climbingGrade", label: "Climbing Grade" },
  { id: "climbingStyle", label: "Climbing Style" },
  { id: "midsoleStiffness", label: "Stiffness" },
  { id: "profile", label: "Profile" },
  { id: "asymmetricCurve", label: "Asymmetric Curve" },
];

const dataKeysTable3 = [
  { id: "rubberType", label: "Rubber Type" },
  { id: "volume", label: "Volume" },
  { id: "heelWidth", label: "Heel Width" },
  { id: "soleThickness", label: "Sole Thickness" },
  { id: "upperMaterial", label: "Upper Material" },
  { id: "lining", label: "Lining" },
  { id: "closureSystem", label: "Closure System" },
];

const measurementLabels: { [key: string]: string } = {
  gender: "Gender",
  width: "Foot Width",
  length: "Foot Length",
};

const useStyles = makeStyles((theme) => ({
  shoePage: {
    "& .navbar": {
      [theme.breakpoints.only("xs")]: {
        borderBottom: "1px solid #ccc",
      },
    },
    minHeight: 700,
    [theme.breakpoints.up("lg")]: {
      paddingTop: theme.spacing(4),
    },
  },
  headerTitle: {
    [theme.breakpoints.only("xs")]: {
      color: "white",
      textAlign: "center",
    },
    [theme.breakpoints.up("sm")]: {
      textAlign: "center",
      width: "33%",
    },
  },
  cell: {
    border: 0,
    fontSize: "0.8175rem",
    padding: theme.spacing(0, 0.125),
    width: "50%",
    "&:first-child": {
      paddingRight: theme.spacing(1),
    },
    "&:last-child": {
      paddingRight: theme.spacing(0.5),
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "0.75rem",
    },
  },
  red: {
    color: "crimson",
  },
  imageGalleryWrapper: {
    [theme.breakpoints.up("lg")]: {
      marginTop: theme.spacing(7),
    },
  },
  leftCol: {
    [theme.breakpoints.only("xs")]: {
      paddingBottom: "0 !important",
    },
  },
  rightCol: {
    [theme.breakpoints.only("xs")]: {
      marginBottom: theme.spacing(2),
      paddingTop: "0 !important",
    },
  },
  rightColBorder: {
    [theme.breakpoints.only("xs")]: {
      paddingBottom: theme.spacing(2),
    },
    [theme.breakpoints.between("sm", "md")]: {
      border: "1px solid #919191",
      borderRadius: "5px",
      padding: theme.spacing(2.5),
    },
  },

  price: {
    fontSize: "0.875rem",
  },

  productDescriptionHeader: {
    borderBottom: "1px solid #e3e3e3",
    color: "rgba(0,0,0,0.87)",
    fontSize: "0.8175rem",
    height: "12px",
    position: "relative",
    marginBottom: "16px",
    "& > span": {
      position: "absolute",
      backgroundColor: "white",
      padding: "0 30px",
      whiteSpace: "nowrap",
      left: "50%",
      transform: "translate(-50%, 0)",
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "0.75rem",
    },
  },

  productDescription: {
    fontSize: "0.8175rem",
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "0.75rem",
    },
    [theme.breakpoints.only("sm")]: {
      minHeight: 360,
    },
    [theme.breakpoints.only("md")]: {
      minHeight: 280,
    },
  },

  title: {
    color: "inherit",
    display: "block",
    textDecoration: "none",
  },
  searchBox: {},
  mfp: {
    fontWeight: "bold",
  },
  fitsTable: {
    color: theme.palette.primary.main,
    maxWidth: "220px",
    margin: "0 auto"
  },
  savedSizeRow:{
    "& > td:first-child": {
      textAlign: "right",
      [theme.breakpoints.up("sm")]: {
        textAlign: "left"
      }
    },
    "& > td:nth-child(2)": {
      textAlign: "left",
      [theme.breakpoints.up("sm")]: {
        textAlign: "right"
      }
    }
  },
  updateSizeButton: {
    padding: "1px 6px",
    [theme.breakpoints.only("xs")]: {
      fontSize: ".63rem",
      padding: "2px 8px"
    }
  },
  updateSizeButtonCell: {
    [theme.breakpoints.only("xs")]: {
      paddingRight: "0px"
    }
  },
  productTitle: {
    borderBottom: "1px solid #e8e8e8",
    position: "relative",
    [theme.breakpoints.only("xs")]: {
      backgroundColor: "#242B38",
      color: "#fff",

      "& b": {
        fontWeight: 800,
      },
    },
  },
  productTitleTextWrapper: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      minHeight: 39,
      paddingTop: theme.spacing(1),
    },
  },
  backLink: {
    color: "white",
    cursor: "pointer",
    left: "8px",
    position: "absolute",
    top: "50%",
    transform: "translateY(-48%)",
    [theme.breakpoints.up("sm")]: {
      color: "#000",
      display: "inline-flex",
      alignItems: "center",
    },
    [theme.breakpoints.up("lg")]: {
      top: "30px",
    },
    "&:hover $backLinkText": {
      textDecoration: "underline",
    },
  },
  backLinkText: {
    lineHeight: 1,
  },
  backLinkIcon: {
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.2rem",
    },
  },
  dataTable: {
    "& td:nth-child(2)": {
      color: "#707074",
    },
  },
  toggleViewSavedFootSize: {
    fontSize: "0.6875rem",
    fontWeight: 400,
    textDecoration: "underline",
    textTransform: "none",
    [theme.breakpoints.only("xs")]: {
      paddingBottom: "3px",
      paddingTop: "0px"
    },
    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
    "&:focus": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
  },
  currentMeasurementBox: {
    // Used by two Box elements
    [theme.breakpoints.only("xs")]: {
      margin: "0px 24px 0px"
    }
  },
  currentMeasurementVal: {
    "&::first-letter": {
      textTransform: "capitalize",
    },
  },
  aboutText: {
    textAlign: "center",
    [theme.breakpoints.between("xs", "md")]: {
      fontSize: "0.5625rem",
    },
  },

  definitionsLink: {
    borderWidth: 0,
    fontSize: "0.6875rem",
    fontWeight: 400,
    textDecoration: "underline",
    textTransform: "none",

    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
    "&:focus": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
  },
  headerBtn: {
    backgroundColor: "#FFD70D",
    borderRadius: theme.spacing(1.5),
    boxShadow: "none",
    fontSize: "0.6875rem",
    minWidth: "100px",
    padding: theme.spacing(1.25, 1.25, 1.25, 1.25),
    textTransform: "none",
    [theme.breakpoints.only("xs")]: {
      borderRadius: "8px",
      marginTop: '2px',
      textDecoration: "none",
      fontSize: "0.75rem",
      padding: "8px 28px 8px 28px"
    },
    [theme.breakpoints.up("lg")]: {
      "& .MuiButton-label": {
        display: "inline-block",
        padding: "0 8px",
      },
    },
    "&:hover": {
      backgroundColor: "#ecb100",
      boxShadow: "none",
    },
  },
  affiliateColumn: {
    justifyContent: 'center'
  },
  affiliateBox: {
    textAlign: 'center',
    width: 'fit-content',
    [theme.breakpoints.only("sm")]: {
      margin: 'auto'
    },
    [theme.breakpoints.only("xs")]: {
      margin: 'auto',
      padding: '8px 16px 8px 16px'
    },
    [theme.breakpoints.up("sm")]: {
      border: '1px solid #e8e8e8',
      borderRadius: '5px',
      padding: '4px 21px 10px 19px',
      marginTop: '4px',
    }
  },
  affiliateCells: {
    [theme.breakpoints.up("sm")]: {
      paddingTop: '10px'
    },
    [theme.breakpoints.only("xs")]: {
      paddingTop: '2px'
    }

  },
  affiliateCellsCol2: {
    [theme.breakpoints.only("sm")]: {
      paddingLeft: '8px'
    },
    [theme.breakpoints.only("xs")]: {
      paddingLeft: '9px'
    }
  }
}));

interface Product {
  [key: string]: any;
}

interface Recommended {
  [key: string]: any;
}

const api = new API();

const Shoe: React.SFC = () => {
  const windowSize = useWindowSize();
  const { state } = React.useContext(Store);
  const classes = useStyles();
  let history = useHistory();
  let { id } = useParams<RouteParams>();
  const [product, setProduct] = React.useState<Product>({});
  const [recommended, setRecommendedSize] = React.useState<Recommended>({});
  const [viewSavedFootSize, setViewSavedFootSize] = React.useState(false);
  const [definitionsOpen, setDefinitionsOpen] = React.useState(false);

  React.useEffect(() => {
    fetchProduct();
  }, [id]);

  const fetchProduct = async () => {
    const product = await api.getProduct({ id });
    setProduct(product);
  };

  React.useEffect(() => {
    const { length } = state.measurements;
    if (id && length) {
      fetchRecommendedSizes(id, length);
    }
  }, [id, state.measurements]);

  const fetchRecommendedSizes = async (id: string, length: string) => {
    const recommended = await api.getRecommendedSizes({
      id,
      length,
    });

    Object.keys(recommended).forEach((key) => {
      if (key !== "sizeUnit" && key !== "gender") {
        let value = `${recommended[key]} ${recommended["sizeUnit"]}`;
        if (recommended["sizeUnit"] === "US" && recommended["gender"]) {
          value += ` ${recommended["gender"]}`;
        }
        recommended[key] = value;
      }
    });
    setRecommendedSize(recommended);
  };

  const renderMeasurement = (key: string) => {
    const value = state.measurements[key];
    if (key === "length" && value) {
      return value + " cm";
    }

    return value || "-";
  };

  const renderBackLink = () => (
    <Box
      component="span"
      className={classes.backLink}
      onClick={() => history.goBack()}
    >
      <ChevronLeft className={classes.backLinkIcon} />
      <Hidden only="xs">
        <small className={classes.backLinkText}>Back to results</small>
      </Hidden>
    </Box>
  );

  const SavedFootSizeUI = () => (
    <>
      <Box textAlign="center" className={classes.currentMeasurementBox}>
        <Button
          onClick={() => setViewSavedFootSize((value) => !value)}
          className={classes.toggleViewSavedFootSize}
          size="small"
        >
          {viewSavedFootSize ? "Hide" : "View"} Saved Foot Size
        </Button>
      </Box>
      {viewSavedFootSize && (
        <Box pb={1} className={classes.currentMeasurementBox}>
          <Table>
            <TableBody>
              <TableRow className={classes.savedSizeRow}>
                <TableCell className={classes.cell}>
                  <b>Dimension</b>
                </TableCell>
                <TableCell className={classes.cell}>
                  <b>Saved Size</b>
                </TableCell>
              </TableRow>
              {Object.keys(measurementLabels).map((key) => (
                <TableRow key={key} className={classes.savedSizeRow}>
                  <TableCell className={classes.cell}>
                    {measurementLabels[key]}
                  </TableCell>
                  <TableCell
                    className={classnames(
                      classes.cell,
                      classes.currentMeasurementVal
                    )}
                  >
                    {renderMeasurement(key)}
                  </TableCell>
                </TableRow>
              ))}

              <TableRow className={classes.savedSizeRow}>
                <TableCell className={classes.cell}></TableCell>
                <TableCell className={classnames(classes.cell,classes.updateSizeButtonCell)} >
                  <Link
                    to="/size-tool"
                    style={{
                      display: "inline-block",
                      paddingTop: "3px",
                      textDecoration: "none",
                    }}
                  >
                    <MFPButton size="small" className={classes.updateSizeButton}>
                      Update Size
                    </MFPButton>
                  </Link>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      )}
    </>
  );

  const getMainImageUrls = () => {
    switch (windowSize) {
      case "mobile":
        return product.mediumImgUrls;
      case "tablet":
        return product.mediumImgUrls;
      default:
        return product.largeImgUrls;
    }
  };

  const renderAffiliates = () => {
    if (product && product.affiliates && product.affiliates.length) {

      const rows = product.affiliates.map((affiliate: any) => (
        <TableRow>
          <TableCell style={{ textAlign: 'right' }} className={classnames(classes.affiliateCells, classes.cell)}><b>${affiliate.price}</b></TableCell>
          <TableCell className={classnames(classes.affiliateCells, classes.affiliateCellsCol2, classes.cell)}>
            <Button
              variant="contained"
              size="large"
              className={classes.headerBtn}
              onClick={() => window.open(affiliate.buyUrl)}
            >
              {affiliate.supplier}
            </Button>
          </TableCell>
        </TableRow>
      ))

      return (
        <Grid item xs={12} sm={12} md={6} className={classes.affiliateColumn}>
          <Box className={classes.affiliateBox}>
            <Table style={{tableLayout: 'fixed', width: 'unset', margin: 'auto'}}>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.cell} style={{width: '40%'}}></TableCell>
                  <TableCell style={{ textAlign: 'center', width: '60%' }} className={`${classes.cell} ${classes.red} ${classes.affiliateCellsCol2}`}><b>Buy From</b></TableCell>
                </TableRow>
                {rows}
              </TableBody>
            </Table>
          </Box>
        </Grid>
      )
    }
  }

  const renderShoeAttributes = () => {
    return (
      <Fragment>
        <Hidden>
          <Box textAlign="center">
            <Box pt={1} pb={1} style={{ paddingBottom: "0px" }}>
              <Typography variant="h6">
                {product.brand} {product.model} - {product.gender}
              </Typography>
            </Box>
          </Box>
        </Hidden>

        <Table className={classes.dataTable}>
          <TableBody>
            <TableRow>
              <TableCell align="right" className={classes.cell}>
                Retail Price
              </TableCell>
              <TableCell align="left" className={classes.cell}>
                {formatPrice(product.price)}
              </TableCell>
            </TableRow>
            {dataKeysTable1.map(({ id, label }) => (
              <TableRow key={id}>
                <TableCell align="right" className={classes.cell}>
                  {label}
                </TableCell>
                <TableCell align="left" className={classes.cell}>
                  {Array.isArray(product[id])
                    ? product[id].join(", ")
                    : product[id]}
                  {label === "Sole Thickness" && " mm"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <Hr style={{ borderColor: "#e3e3e3", maxWidth: "85%" }} />

        <Table className={classes.dataTable}>
          <TableBody>
            {dataKeysTable2.map(({ id, label }) => (
              <TableRow key={id}>
                <TableCell align="right" className={classes.cell}>
                  {label}
                </TableCell>
                <TableCell align="left" className={classes.cell}>
                  {Array.isArray(product[id])
                    ? product[id].join(", ")
                    : product[id]}
                  {label === "Sole Thickness" && " mm"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <Hr style={{ borderColor: "#e3e3e3", maxWidth: "85%" }} />

        <Table className={classes.dataTable}>
          <TableBody>
            {dataKeysTable3.map(({ id, label }) => (
              <TableRow key={id}>
                <TableCell align="right" className={classes.cell}>
                  {label}
                </TableCell>
                <TableCell align="left" className={classes.cell}>
                  {Array.isArray(product[id])
                    ? product[id].join(", ")
                    : product[id]}
                  {label === "Sole Thickness" && " mm"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        

        <Box textAlign="center" pt={1}>
          <Button
            className={classnames(classes.definitionsLink)}
            size="small"
            onClick={() => setDefinitionsOpen(true)}
          >
            View Definitions
          </Button>
        </Box>
      </Fragment>
    )
  }
  const hasAffiliates = product && product.affiliates && product.affiliates.length

  return (
    <div className={classes.shoePage}>
      <Hidden lgUp>
        <Box textAlign="center" mb={2} className={classes.productTitle}>
          {renderBackLink()}

          <Box className={classes.productTitleTextWrapper}>
            <Typography variant="h5" component="h1">
              {product.brand} <b>{product.model}</b>
            </Typography>
            <small>{product.gender}</small>
          </Box>
        </Box>
      </Hidden>

      <Container>
        <Grid
          container
          spacing={4}
          style={{ paddingBottom: "16px", position: "relative" }}
        >
          <Grid item xs={12} sm={6} lg={6} className={classes.leftCol}>
            <Hidden mdDown>{renderBackLink()}</Hidden>

            <Box mb={2} className={classes.imageGalleryWrapper}>
              <ImageGallery
                mainImages={getMainImageUrls()}
                previewImages={product.smallImgUrls}
              />
            </Box>

            <Hidden only="xs">
              <Box textAlign="center">
                <Typography
                  variant="body1"
                  component="h6"
                  gutterBottom
                  className={classes.productDescriptionHeader}
                >
                  <span>Product Description</span>
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography
                  component="p"
                  variant="body1"
                  className={classes.productDescription}
                >
                  {product.description}
                </Typography>
              </Box>
            </Hidden>
          </Grid>

          <Grid item xs={12} sm={6} lg={6} className={classes.rightCol}>
            <Hidden mdDown>
              <Grid container lg={12}>
                <Grid item lg={hasAffiliates ? 6 : 12}>
                  <Box textAlign="center" mb={1}>
                    <Typography
                      component="h1"
                      variant="h5"
                      style={{ fontSize: "1.2rem" }}
                    >
                      {product.brand}{" "}
                      <b style={{ fontWeight: "bold" }}>{product.model}</b>
                    </Typography>
                    <small style={{ fontSize: "0.9rem" }}>{product.gender}</small>
                  </Box>
                </Grid>
              </Grid>
            </Hidden>

            <Box className={classes.rightColBorder}>
              <Grid container xs={12}>
                <Grid item xs={12} sm={12} md={hasAffiliates ? 6 : 12}>
                  <Typography color="error" component="div">
                    <Table className={classes.fitsTable}>
                      <TableBody>
                        <TableRow>
                          <TableCell
                            align="right"
                            className={`${classes.cell} ${classes.red}`}
                          >
                            <b>Fit Style</b>
                          </TableCell>
                          <TableCell
                            className={`${classes.cell} ${classes.red}`}
                            align="left"
                          >
                            <b>Buy Size</b>
                          </TableCell>
                        </TableRow>
                        {Object.entries(fits).map(([key, value]) => (
                          <TableRow key={key}>
                            <TableCell
                              align="right"
                              className={`${classes.cell} ${classes.red}`}
                            >
                              {value.label}
                            </TableCell>
                            <TableCell
                              className={`${classes.cell} ${classes.red}`}
                              align="left"
                            >
                              {recommended[key] || "-"}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Typography>
                  <Box mt={1} mb={0} mx={3}>
                    <SavedFootSizeUI />
                  </Box>
                  <Hidden mdDown>
                    {renderShoeAttributes()}
                  </Hidden>

                </Grid>

                {renderAffiliates()}
              </Grid>
              <Hidden lgUp>
                {renderShoeAttributes()}
              </Hidden>
            </Box>

            <Hidden smUp>
              <Box textAlign="center">
                <Typography
                  variant="body1"
                  component="h6"
                  gutterBottom
                  className={classes.productDescriptionHeader}
                >
                  <span>Product Description</span>
                </Typography>
              </Box>
              <Typography
                component="p"
                variant="body1"
                className={classes.productDescription}
                style={{
                  color: "rgba(0,0,0,0.87)",
                }}
              >
                {product.description}
              </Typography>
            </Hidden>

          </Grid>
        </Grid>
      </Container>

      <Modal
        open={definitionsOpen}
        handleClose={() => setDefinitionsOpen(false)}
      >
        <AttributeDefinitionsModal />
      </Modal>
    </div>
  );
};

export default Shoe;
