import React from "react";
import { Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  fieldset: {
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.up("lg")]: {
      border: "1px solid #b5b5b5",
      marginTop: theme.spacing(3),
      padding: theme.spacing(5),
      position: "relative",
    },
  },
  legend: {
    color: "#000",
    fontSize: "0.75rem",
    marginBottom: theme.spacing(2),
    textAlign: "center",
    whiteSpace: "nowrap",
    [theme.breakpoints.up("lg")]: {
      backgroundColor: "white",
      marginBottom: theme.spacing(3),
      padding: theme.spacing(0, 4),
      position: "absolute",
      left: "50%",
      top: "-9px",
      transform: "translateX(-50%)",
    },
  },
}));

interface FieldSetProps {
  legend: React.ReactNode;
  maxWidth: number;
  style?: object;
}

const Fieldset: React.SFC<FieldSetProps> = ({ children, legend, maxWidth }) => {
  const classes = useStyles();
  return (
    <Box
      className={classes.fieldset}
      style={{ maxWidth: maxWidth, position: "relative" }}
    >
      <Box className={classes.legend}>{legend}</Box>
      {children}
    </Box>
  );
};

export default Fieldset;
