import React from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Hidden,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";

import buildFiltersFromMeasurements from "../../helpers/buildFiltersFromMeasurements";

import { Store } from "../../store";
import API from "../../api";

import ChevronLeft from "@material-ui/icons/ChevronLeft";

import CurrentShoe from "./CurrentShoe";
import FootSize from "./FootSize";
import SneakerSize from "./SneakerSize";

const useStyles = makeStyles((theme) => ({
  tabs: {
    position: "relative",
    textAlign: "center",
    [theme.breakpoints.only("xs")]: {
      backgroundColor: "#242B38",
      padding: theme.spacing(1.25, 0, 2),
      minHeight: 110,
    },
    [theme.breakpoints.up("sm")]: {
      backgroundColor: "#fdfdfd",
      borderBottomColor: theme.palette.grey[400],
      borderBottom: "1px solid",
      padding: theme.spacing(2, 0),
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.up("lg")]: {
      backgroundColor: "#f5f5f5",
      marginBottom: theme.spacing(2),
      padding: theme.spacing(1, 0),
    },
  },
  tabButton: {
    borderRadius: theme.spacing(0.5),
    display: "inline-block",
    fontSize: "0.75rem",
    lineHeight: 1.4,
    margin: theme.spacing(0, 1),
    padding: theme.spacing(1.25, 0.5),
    textTransform: "none",
    "& small": {
      display: "block",
    },
    "&.active": {
      backgroundColor: "#eaeaed",
    },
    "@media (max-width: 720px)": {
      borderColor: "white",
      borderRadius: theme.spacing(4),
      color: "#fff",
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: theme.spacing(1),
      padding: theme.spacing(0.75, 0.5),
      "&.active": {
        backgroundColor: "#ffc000",
        borderColor: "transparent",
        color: "#000",
      },
      "&:last-child": {
        marginBottom: 0,
      },
    },
    [theme.breakpoints.down("md")]: {
      minWidth: "170px",
    },
    [theme.breakpoints.up("lg")]: {
      borderWidth: 0,
      borderBottom: "1px solid #f5f5f5",
      borderRadius: 0,
      margin: "0 40px",
      "&:hover": {
        backgroundColor: "transparent",
      },
      "&.active": {
        backgroundColor: "transparent",
        borderBottom: "1px solid rgba(0,0,0,0.87)",
      },
    },
  },
  saveButton: {
    borderColor: "#151515",
    borderRadius: "50px",
    color: "#151515",
    fontSize: "0.75rem",
    maxWidth: "400px",
    minWidth: "200px",
  },
  title: {
    color: "inherit",
    display: "block",
    textDecoration: "none",
  },
  backLink: {
    color: "#fff",

    fontSize: "1.25rem",
    position: "absolute",
    left: "5px",
    top: "27px",
    transform: "translateY(-50%)",
  },
  mfp: {
    fontWeight: "bold",
    position: "absolute",
    right: "16px",
    top: "42%",
  },
}));

interface TabPanelProps {
  value: number;
  index: number;
}

const TabPanel: React.SFC<TabPanelProps> = ({
  children,
  value,
  index,
  ...other
}) => (
  <Typography
    component="div"
    role="tabpanel"
    hidden={value !== index}
    id={`wrapped-tabpanel-${index}`}
    aria-labelledby={`wrapped-tab-${index}`}
    {...other}
  >
    <Box>{children}</Box>
  </Typography>
);

const tabButtons = [
  {
    id: 0,
    title: "Current Climbing Shoe",
  },
  {
    id: 1,
    title: "Foot Size",
  },
  {
    id: 2,
    title: "Sneaker Size",
  },
];

const api = new API();

const SizeTool = () => {
  let history = useHistory();
  const classes = useStyles();
  const { dispatch } = React.useContext(Store);

  const [activeTab, setActiveTab] = React.useState(0);

  function handleTabChange(newValue: number) {
    setActiveTab(newValue);
  }

  const handleSave = (params: any) => {
    fetchMeasurements(params);
  };

  const fetchMeasurements = async (params: string) => {
    const response = await api.getMeasurements({ params });
    dispatch({
      type: "SET_MEASUREMENTS",
      payload: response,
    });

    history.goBack();
  };

  return (
    <>
      <Box className={classes.tabs}>
        <Link to="/" className={classes.backLink}>
          <ChevronLeft />
        </Link>

        <Hidden mdDown>
          <Box className={classes.tabButton} style={{ marginLeft: "-195px" }}>
            Sizing method:
          </Box>
        </Hidden>

        {tabButtons.map((button) => (
          <Button
            key={button.title}
            variant="outlined"
            className={`${classes.tabButton} ${
              activeTab === button.id ? "active" : null
            }`}
            onClick={() => handleTabChange(button.id)}
          >
            {button.title}
          </Button>
        ))}
      </Box>

      <Container>
        <Box textAlign="center" mb={4}>
          <Hidden xsDown>
            <Box mb={2}>
              <Typography variant="body1" style={{ fontWeight: 500 }}>
                You Tell Us: Either your current climbing shoe, foot size, or
                sneaker size.
                <br />
                We Tell You: What size to buy for practically every climbing
                shoe out there.
              </Typography>
            </Box>
          </Hidden>

          <TabPanel value={activeTab} index={0}>
            <CurrentShoe handleSave={handleSave} />
          </TabPanel>

          <TabPanel value={activeTab} index={1}>
            <FootSize handleSave={handleSave} />
          </TabPanel>

          <TabPanel value={activeTab} index={2}>
            <SneakerSize handleSave={handleSave} />
          </TabPanel>
        </Box>
      </Container>
    </>
  );
};

export default SizeTool;
