import React, { ChangeEvent } from "react";

import {
  Box,
  Grid,
  Hidden,
  TextField,
  Typography,
  makeStyles,
  withStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  footBox: {
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2, 4),
    },
  },
  footBoxText: {
    backgroundColor: "#fff",
    border: "1px solid #f3f3f3",
    padding: theme.spacing(1),
  },
  footImg: {
    width: "100%",
  },
  cmLabel: {
    marginLeft: "12px",
    marginRight: "-32px",
    height: 30,
    display: "inline-flex",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      marginRight: "-82px",
    },
  },
}));

const SmallInput = withStyles((theme) => ({
  root: {
    margin: 0,
    width: 80,
    "& input": {
      fontSize: "12px",
      padding: theme.spacing(0.75),
      textAlign: "center",
    },
    "& .MuiFormHelperText-root": {
      margin: theme.spacing(0.5, 0),
      position: "absolute",
      width: "180px",
      top: "100%",
      left: "50%",
      transform: "translate(-50%)",
      textAlign: "center",

      "&:first-letter": {
        textTransform: "uppercase",
      },
    },
  },
}))(TextField);

const Foot: React.SFC<{
  error?: string;
  image?: string;
  instructionsType: string;
  isSubmitting: boolean;
  onChange: (value: string) => void;
  title: string;
  value: any;
}> = ({
  error,
  image,
  instructionsType,
  isSubmitting,
  onChange,
  title,
  value,
}) => {
  const classes = useStyles();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const number = event.target.value;
    onChange(number);
  };

  return (
    <Grid item xs={6} style={{ maxWidth: "360px" }}>
      <Box className={classes.footBox}>
        <Hidden only="xs">
          <Box className={classes.footBoxText} mb={1}>
            <Typography gutterBottom>
              <b>
                <Typography component="span" variant="inherit">
                  {title}
                </Typography>
              </b>
            </Typography>
            <Typography variant="body2" gutterBottom>
              Stand bare foot on a sheet of paper
              <br />
              Draw 2 lines at {instructionsType} part of foot
              <br />
              Measure in CENTIMETERS
            </Typography>{" "}
          </Box>
        </Hidden>
        <Box pb={1}>
          <img src={image} alt="" className={classes.footImg} />
        </Box>
        <SmallInput
          value={value}
          onChange={handleChange}
          margin="dense"
          variant="outlined"
          error={isSubmitting && !!error}
          helperText={isSubmitting && error}
        />
        <Box className={classes.cmLabel}>
          <Hidden xsDown>Centimeters</Hidden>
          <Hidden smUp>cm</Hidden>
        </Box>
      </Box>
    </Grid>
  );
};

export default Foot;
