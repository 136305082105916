const definitions: {
  [key: string]: any;
} = {
  footWidth: {
    label: { default: "Foot Width", xs: "Foot Width" },
  },
  toeShape: {
    label: { default: "Toe Shape", xs: "Toe Shape" },
  },
  brand: {
    label: { default: "Brand", xs: "Brand" },
  },
  climbingGrade: {
    label: { default: "Climbing Grade", xs: "Climbing Grade" },
  },
  climbingStyle: {
    label: { default: "Climbing Style", xs: "Climbing Style" },
  },
  midsoleStiffness: {
    label: { default: "Stiffness", xs: "Stiffness" },
  },
  profile: {
    label: { default: "Profile", xs: "Profile" },
  },
  asymmetricCurve: {
    label: { default: "Asymmetric Curve", xs: "Curve" },
  },
  rubberType: {
    label: { default: "Rubber Type", xs: "Rubber" },
  },
  volume: {
    label: { default: "Volume", xs: "Volume" },
  },
  heelWidth: {
    label: { default: "Heel Width", xs: "Heel Width" },
  },
  soleThickness: {
    label: { default: "Sole Thickness", xs: "Sole" },
  },
  upperMaterial: {
    label: { default: "Upper Material", xs: "Upper" },
  },
  lining: {
    label: { default: "Lining", xs: "Lining" },
  },
  closureSystem: {
    label: { default: "Closure System", xs: "Closure" },
  },
  price: {
    label: { default: "Price", xs: "Price" },
  },
  gender: {
    label: { default: "Gender", xs: "Gender" },
  },
};

export default definitions;
