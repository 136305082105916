import React from "react";

import API from "../../api";

import { Store } from "../../store";

import useWindowSize from "../../hooks/useWindowSize";

import Home from "./Home";

const api = new API();

const HomeContainer = () => {
  const windowSize = useWindowSize();
  const { state, dispatch } = React.useContext(Store);
  const {
    // currentPage,
    sortBy,
    orderBy,
    selectedAttribute,
    selectedAttributeOptions,
  } = state.home;
  const [products, setProducts] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(1);

  const getProductsAmount = windowSize === "tablet" ? 21 : 20;

  // const setCurrentPage = (page: number) => {
  //   dispatch({
  //     type: "SET_CURRENT_PAGE",
  //     payload: page,
  //   });
  // };

  const loadMore = () => setCurrentPage(currentPage + 1);

  const fetchProductsAction = async () => {
    setLoading(true);

    if (!windowSize && currentPage === 1) {
      // wait for windowSize to be defined so we can get correct amount of results
      return;
    }

    let url = `?limit=${getProductsAmount}&page=${currentPage}&sortBy=${sortBy}&orderBy=${orderBy}`;
    if (selectedAttribute !== "View All") {
      selectedAttributeOptions.forEach((item: string) => {
        url += `&${item.split("_")[0]}[]=${item.split("_")[1]}`;
      });
    }
    const response = await api.getProducts(url);

    if (response.message) {
      setLoading(false);
      setError(response.message);
      setProducts([]);
    } else {
      const updatedProducts =
        currentPage === 1 ? response : products.concat(response);
      setProducts(updatedProducts);
      setLoading(false);
      setError("");
    }
  };

  React.useEffect(() => {
    setCurrentPage(1);
  }, [selectedAttributeOptions, sortBy]);

  React.useEffect(() => {
    fetchProductsAction();
  }, [currentPage, selectedAttributeOptions, sortBy, orderBy, windowSize]);

  const showLoadMore = products.length / getProductsAmount === currentPage;

  return (
    <Home
      currentPage={currentPage}
      loading={loading}
      loadMore={loadMore}
      products={products}
      showLoadMore={showLoadMore}
    />
  );
};

export default HomeContainer;
