import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core";

import MFPButton from "../../components/Button";

const useStyles = makeStyles((theme) => ({
  button: {
    marginBottom: theme.spacing(1),
  },
}));

const ColumnButton: React.SFC<{
  selected?: boolean;
  onClick: () => void;
}> = ({ children, selected = false, onClick }) => {
  const classes = useStyles();

  return (
    <MFPButton
      className={classnames(classes.button)}
      color="primary"
      onClick={onClick}
      selected={selected}
      fullWidth
    >
      {children}
    </MFPButton>
  );
};

export default ColumnButton;
