import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  masthead: {
    backgroundColor: "#242B38",
    paddingTop: 4,
    paddingBottom: 0,
    textAlign: "center",
  },

  titleText: {
    color: theme.palette.grey[500],

    [theme.breakpoints.only("xs")]: {
      fontSize: "0.5625rem",
    },
  },
}));

const Masthead = () => {
  const classes = useStyles();
  return (
    <Box className={`${classes.masthead} masthead`} py={0.5}>
      <Typography
        variant="overline"
        display="block"
        className={classes.titleText}
      >
        the guide to mountain footwear
      </Typography>
    </Box>
  );
};

export default Masthead;
