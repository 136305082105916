import React from "react";
import useCombinedReducers from "use-combined-reducers";
import { useStorageReducer } from "react-storage-hooks";

import {
  currentShoeReducer,
  currentShoeInitialData,
} from "./reducers/currentShoe";
import {
  dictionaryReducer,
  dictionaryInitialData,
} from "./reducers/dictionary";
import { footSizeReducer, footSizeInitialData } from "./reducers/footSize";
import { homeReducer, homeInitialData } from "./reducers/home";
import {
  measurementsReducer,
  measurementsInitialData,
} from "./reducers/measurements";
import {
  sneakerSizeReducer,
  sneakerSizeInitialData,
} from "./reducers/sneakerSize";

export function useLocalStorageReducer(...args) {
  return useStorageReducer(localStorage, ...args);
}

export const Store = React.createContext();

export function StoreProvider(props) {
  const [state, dispatch] = useCombinedReducers({
    currentShoe: useLocalStorageReducer(
      "mfpCurrentShoe",
      currentShoeReducer,
      currentShoeInitialData
    ),
    dictionary: React.useReducer(dictionaryReducer, dictionaryInitialData),
    footSize: useLocalStorageReducer(
      "mfpFootSize",
      footSizeReducer,
      footSizeInitialData
    ),
    home: useLocalStorageReducer("mfpHome", homeReducer, homeInitialData),
    measurements: useLocalStorageReducer(
      "mfpMeasurements",
      measurementsReducer,
      measurementsInitialData
    ),
    sneakerSize: useLocalStorageReducer(
      "mfpSneakerSize",
      sneakerSizeReducer,
      sneakerSizeInitialData
    ),
  });
  const value = { state, dispatch };
  return <Store.Provider value={value}>{props.children}</Store.Provider>;
}
