import React from "react";
import { Link } from "react-router-dom";

import LogoImg from "../images/LogoWhite.png";

const Logo = () => (
  <Link to="/">
    <img src={LogoImg} style={{ maxWidth: "100%", paddingTop: '5px' }} alt="" />
  </Link>
);

export default Logo;
