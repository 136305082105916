import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Hidden,
  makeStyles,
  withStyles,
} from "@material-ui/core";

import ModalCloseIcon from "../images/modal-close.jpg";
import ModalClose2Icon from "../images/close-x.png";

const useStyles = makeStyles((theme) => ({
  modalClose: {
    flexShrink: 0,
    letterSpacing: 0,
    margin: "1rem auto",
    minWidth: "0",
    [theme.breakpoints.only("xs")]: {
      fontSize: "2.5rem",
      width: "48px",
      minHeight: "48px",
      lineHeight: 1,
      fontWeight: 300,
      padding: 0,
      textAlign: "center",
    },
    [theme.breakpoints.up("sm")]: {
      backgroundColor: "#FFC402",
      borderRadius: 0,
      fontSize: "2.5rem",
      lineHeight: "1",
      fontWeight: "300",
      padding: "0",
      width: "3rem",
      height: "3rem",
      "&:hover": {
        backgroundColor: "#ecb100",
      },
    },
    [theme.breakpoints.up("lg")]: {
      float: "right",
      top: "-8px",
      right: "-24px",
      margin: 0,
      padding: 0,
      position: "relative",
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  },
}));

const StyledDialog = withStyles((theme) => ({
  paper: {
    borderRadius: "0",
    [theme.breakpoints.only("xs")]: {
      margin: theme.spacing(1),
    },
  },
}))(Dialog);

const StyledDialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 2, 2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(0, 8, 4),
    },
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(1, 4, 4, 4),
    },
  },
}))(DialogContent);

interface Props {
  handleClose: () => void;
  open: boolean;
}

const Modal: React.SFC<Props> = ({ children, handleClose, open }) => {
  const classes = useStyles();
  return (
    <StyledDialog onClose={handleClose} open={open} maxWidth="xl">
      <Hidden lgUp>
        <Button className={classes.modalClose} onClick={handleClose}>
          <img src={ModalCloseIcon} style={{ width: 48 }} alt="close modal" />
        </Button>
      </Hidden>
      <StyledDialogContent>
        {" "}
        <Hidden mdDown>
          <Button className={classes.modalClose} onClick={handleClose}>
            <img
              src={ModalClose2Icon}
              style={{ width: 18 }}
              alt="close modal"
            />
          </Button>
        </Hidden>
        {children}
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default Modal;
