import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";

import BrandsBg from "../images/banner-thin.png";

const useStyles = makeStyles((theme) => ({
  brands: {
    color: "white",
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    position: "relative",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      backgroundColor: "#111",
      background: `center/cover no-repeat url("${BrandsBg}")`,
      height: "28px",
    },
  },

  tagLine: {
    color: "#cecece",
    fontSize: "1rem",
    fontWeight: 900,
  },
}));

interface Props {
  className?: string;
}

const Brands: React.SFC<Props> = ({ children, className }) => {
  const classes = useStyles();
  return (
    <Box className={`${classes.brands} ${className}`}>
      <Typography className={classes.tagLine}>
        Your perfect shoe, in your perfect size
      </Typography>
      {children}
    </Box>
  );
};

export default Brands;
