export const currentShoeInitialData = {
  brand: "Black Diamond",
  model: "aspect-men",
  size: 1.5,
  fit: "comfortFit",
};

export function currentShoeReducer(state, action) {
  switch (action.type) {
    case "SET_CURRENT_SHOE":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
