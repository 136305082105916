import React from "react";
import { Box, Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  column: {
    width: 180,
  },
  columnHeader: {
    fontSize: "0.75rem",
  },
}));

const Column: React.SFC<{ maxWidth?: number; title: React.ReactNode }> = ({
  children,
  maxWidth,
  title,
}) => {
  const classes = useStyles();

  return (
    <Grid item className={classes.column} style={{ maxWidth }}>
      <Box textAlign="center" mb={1}>
        <b className={classes.columnHeader}>{title}</b>
      </Box>
      {children}
    </Grid>
  );
};

export default Column;
