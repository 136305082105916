import React from "react";
import classnames from "classnames";
import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  button: {
    border: "1px solid #e0e0e0",
    color: "#000",
    fontSize: "0.75rem",
    fontWeight: 300,
    textTransform: "none",
    [theme.breakpoints.only("xs")]: {
      fontSize: "0.6875rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontWeight: 400,
      "&:hover": {
        backgroundColor: "#F3F9FF",
        borderColor: "#0066c0",
      },
    },
  },
  active: {
    [theme.breakpoints.down("md")]: {
      color: "#000",
      borderColor: "#0066c0",
      backgroundColor: "#FFF",
    },
  },
  selected: {
    color: "#0066c0 !Important",
    borderColor: "#0066c0",
    backgroundColor: "#F3F9FF",
    "&:hover": {
      color: "#000",
      borderColor: "#0066c0",
      backgroundColor: "#F3F9FF",
    },
  },
}));

interface Props {
  active?: boolean;
  className?: string;
  color?: "default" | "primary" | "secondary";
  form?: string;
  fullWidth?: boolean;
  onClick?: () => void;
  selected?: boolean;
  size?: "medium" | "large" | "small";
  style?: object;
  type?: "button" | "reset" | "submit";
  variant?: "outlined";
}

const MFPButton: React.FunctionComponent<Props> = ({
  active,
  children,
  className,
  color,
  form,
  fullWidth,
  onClick,
  selected,
  size,
  style,
  type,
  variant,
}) => {
  const classes = useStyles();
  return (
    <Button
      className={classnames(
        classes.button,
        { [classes.active]: active, [classes.selected]: selected, active },
        className
      )}
      color={color}
      form={form}
      fullWidth={fullWidth}
      onClick={onClick}
      size={size}
      style={style}
      type={type}
      variant={variant}
    >
      {children}
    </Button>
  );
};

export default MFPButton;
