export const footSizeInitialData = {
  gender: "men",
  footLength: "",
  footWidth: "",
};

export function footSizeReducer(state, action) {
  switch (action.type) {
    case "SET_FOOT_SIZE":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
