import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Box, Hidden, Toolbar } from "@material-ui/core";
import CurrentMeasurement from "./CurrentMeasurement";

import Logo from "./Logo";
import LogoAsText from "./LogoAsText";

import SearchInput from "./SearchInput";

const useStyles = makeStyles((theme) => ({
  navbar: {
    backgroundColor: "#242B38",
    "& .searchInput": {
      backgroundColor: "white",
      "& input": {
        color: "#242B38",
      },
      "& input::placeholder": {
        color: "#242B38",
      },
      "& .searchIcon": {
        backgroundColor: "#E2E0E0",
      },
    },
    "& .currentMeasurement": {
      color: "white",
    },
  },
  toolbar: {
    minHeight: 0,
    padding: theme.spacing(0, 2, 0.5),
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "space-between",
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1, 2),
    },
  },
  logo: {
    width: theme.spacing(5),
  },
  logoAndMeasurements: {
    display: "flex",
    alignItems: "center",
  },
}));

interface Props {
  children?: React.ReactNode;
}

const NavBar: React.SFC<Props> = ({ children = null }) => {
  const classes = useStyles();

  return (
    <div className={`navbar ${classes.navbar}`}>
      <AppBar
        position="static"
        color="inherit"
        style={{ background: "transparent", boxShadow: "none" }}
      >
        <Toolbar className={classes.toolbar}>
          <div className={classes.logoAndMeasurements}>
            <Hidden smDown>
              <Box mr={1} className={classes.logo}>
                <Logo />
              </Box>
            </Hidden>
            <Hidden only="xs">
              <LogoAsText />
            </Hidden>
            <Hidden only="xs">
              <CurrentMeasurement />
            </Hidden>
          </div>

          <Hidden smUp>
            <LogoAsText />
          </Hidden>

          <SearchInput />
        </Toolbar>
      </AppBar>

      {children}
    </div>
  );
};

export default NavBar;
