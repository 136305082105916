export const widths: { [key: string]: any } = {
  narrow: {
    label: "Narrow"
  },
  medium: {
    label: "Medium"
  },
  wide: {
    label: "Wide"
  }
};
