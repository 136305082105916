import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  hr: {
    borderColor: "#e3e3e3",
    borderStyle: "solid",
    borderWidth: "1px 0 0",
  },
}));

interface Props {
  style?: object;
}

const Hr: React.SFC<Props> = ({ style }) => {
  const classes = useStyles();
  return <hr className={classes.hr} style={style} />;
};

export default Hr;
