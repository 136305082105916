import React from "react";
import { Box, Button, Container, makeStyles, Hidden } from "@material-ui/core";

import { CurrentMeasurementPageBottom } from "./CurrentMeasurement";
import Modal from "../components/Modal";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: "#242B38",
    color: "white",
  },
  backToTop: {
    backgroundColor: "#E8E8E8",
    color: "#6C6C6C",
    textAlign: "center",
  },
  backToTopLink: {
    cursor: "pointer",
  },
  copyright: {
    [theme.breakpoints.only("xs")]: {
      textAlign: "center",
    },
  },
  button: {
    fontSize: '.7rem',
    minWidth: '45px'
  }
}));

const Footer = () => {
  const classes = useStyles();
  const [viewPrivacyOpen, setViewPrivacyOpen] = React.useState(false);
  const [viewAboutOpen, setViewAboutOpen] = React.useState(false);

  const goToTop = () => {
    window.scrollTo(0, 0);
  };

  const renderLinks = () => {
    return (
      <>
        <Button
          size="small"
          style={{color: 'white', textTransform: 'none', textAlign: 'right'}}
          className={classes.button}
          onClick={() => setViewAboutOpen(true)}
        >
          About
        </Button>
        <Button
          size="small"
          style={{color: 'white', textTransform: 'none', textAlign: 'right'}}
          className={classes.button}
          onClick={() => setViewPrivacyOpen(true)}
        >
          Privacy
        </Button>
      </>
    )
  }

  return (
    <>
      <CurrentMeasurementPageBottom />

      <div className={classes.footer}>
        <Box p={1} py={1.5} className={classes.backToTop}>
          <small className={classes.backToTopLink} onClick={goToTop}>
            Back to top
          </small>
        </Box>
        <div className={classes.copyright}>
          <Container>
            <Box p={2}> 
              <small>
                &copy; 2019-2024,
                <Hidden smUp>
                  <br />
                </Hidden>
                &nbsp;<b>Mountain Footwear Project</b>
                <Hidden smUp>
                <br />
              </Hidden>
              <Hidden only="xs">
                &nbsp;  
              </Hidden>
              { renderLinks() }
              </small>
            </Box>
          </Container>
        </div>
      </div>
      <Modal
        open={viewPrivacyOpen}
        handleClose={() => setViewPrivacyOpen(false)}
      >
          <Box>
            <p style={{textAlign: 'center', fontWeight: 'bold'}}>Privacy Policy</p>
            <p>This Privacy Policy describes our policies and procedures on the collection and use of information when you visit Mountain Footwear Project. By using the site, you agree to the collection and use of information in accordance with the following.</p>

            <p>Types of data and how they are used: <br />
            Personal Data: <br />
            Size Tool information gets sent to our size converter and a cookie is generated. This cookie allows us to show you what size to buy for each shoe listed on the site. No one sees any information entered into the Size Tool and it does not get shared with anyone outside of Mountain Footwear Project.</p>

            <p>Analytics: <br />
            We use Google Analytics to make sure we provide you with a fast and reliable service. Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of Mountain Footwear Project.</p>

            <p>Affiliate: <br />
            When you click on an affiliate link, we share product specific information with the vendor so you are taken to the proper page. Affiliate links are located on relevant pages and allow us to earn a small commission when you place an order from the linked vendor. These commissions are what keep the lights on at Mountain Footwear Project and will always be paid for by the vender. You will never be charged extra when placing an order via any of our affiliate links.</p>

            <p>Contact Us: <br />
            If you have any questions about this Privacy Policy send us a message on Instagram. @mountainfootwearproject</p>
          </Box>
      </Modal>
      <Modal
        open={viewAboutOpen}
        handleClose={() => setViewAboutOpen(false)}
      >
          <Box>
            <p style={{textAlign: 'center', fontWeight: 'bold'}}>Our mission is to organize the world’s climbing shoes so others don’t have to.</p>
            <p>Mountain Footwear Project started out one weekend in the middle of a beautiful Fall season at Rumney. We now provide customers with the most detailed product attribute and sizing data in the industry. Browse and compare as our team has created something great.</p>
          </Box>
      </Modal>
    </>
  );
};

export default Footer;
