import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Hidden } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  title: {
    color: "inherit",
    display: "block",
    textDecoration: "none",
    [theme.breakpoints.up("sm")]: {
      marginRight: theme.spacing(4),
    },
  },
  logoAsText: {
    color: "white",
    fontWeight: 900,
    fontSize: "12px",
    lineHeight: 1,
    display: "block",
  },
}));

const LogoAsText = () => {
  const classes = useStyles();
  return (
    <Link to="/" className={classes.title}>
      <span className={classes.logoAsText}>
        <span>Mountain</span>
        <Hidden only="xs">
          <br />
        </Hidden>
        <Hidden smUp>&nbsp;</Hidden>
        Footwear
        <Hidden only="xs">
          <br />
        </Hidden>
        <Hidden smUp>&nbsp;</Hidden>
        <span>Project</span>
      </span>
    </Link>
  );
};

export default LogoAsText;
