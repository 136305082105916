const serverEndpoint = "/api";

export default class MfpApi {
  async getProducts(queryString?: string) {
    let url = `${serverEndpoint}/products`;
    if (queryString) {
      url += queryString;
    }
    const data = await fetch(url, {
      mode: "cors",
      credentials: "include",
    });
    const response = await data.json();
    return response;
  }

  async getProduct({ id }: { id?: string }) {
    const data = await fetch(`${serverEndpoint}/products/${id}`, {
      method: "GET",
      mode: "cors",
    });

    const response = await data.json();
    return response;
  }

  async search(query: string) {
    const data = await fetch(`${serverEndpoint}/search?q=${query}`, {
      method: "GET",
      mode: "cors",
    });

    const response = await data.json();
    return response;
  }

  async getDictionary() {
    const data = await fetch(`${serverEndpoint}/dictionary`, {
      method: "GET",
      mode: "cors",
    });

    const response = await data.json();
    return response;
  }

  async getMeasurements({ params }: { params?: string }) {
    let url = `${serverEndpoint}/sizes?${params}`;
    const data = await fetch(url, {
      method: "GET",
      mode: "cors",
    });

    const response = await data.json();
    return response;
  }

  async getRecommendedSizes({ id, length }: { id?: string; length?: string }) {
    let url = `${serverEndpoint}/products/${id}/length/${length}`;
    const data = await fetch(url, {
      method: "GET",
      mode: "cors",
    });

    const response = await data.json();
    return response;
  }
}
