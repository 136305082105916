import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#007CCC",
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "sans-serif",
    ].join(","),
    h5: {
      fontSize: "1rem",
    },
    h6: {
      fontSize: "0.875rem",
    },
    body1: {
      fontSize: "0.75rem",
    },
    body2: {
      fontSize: "0.6875rem",
    },
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: "#0066c0",
        "&:hover": {
          backgroundColor: "#0066c0 !important",
        },
      },
    },
    MuiTypography: {
      overline: {
        textTransform: "none",
      },
    },
    MuiDialog: {
      paperScrollPaper: {
        maxHeight: "calc(100% - 16px)",
      },
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
});

export default theme;
