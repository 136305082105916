export const homeInitialData = {
  currentPage: 1,
  orderBy: "asc",
  sortBy: "brand",
  selectedAttribute: "View All",
  selectedAttributeOptions: [],
};

export function homeReducer(state, action) {
  switch (action.type) {
    case "CLEAR_FILTERS":
      return {
        ...state,
        selectedAttribute: "View All",
        selectedAttributeOptions: [],
      };

    case "SET_SELECTED_ATTRIBUTE":
      return {
        ...state,
        selectedAttribute: action.payload,
      };

    case "CLEAR_SELECTED_ATTRIBUTE_OPTIONS":
      return {
        ...state,
        selectedAttributeOptions: state.selectedAttributeOptions.filter(
          (item) => item.startsWith(state.selectedAttribute) === false
        ),
      };

    case "SET_SELECTED_ATTRIBUTE_OPTIONS":
      if (state.selectedAttributeOptions.includes(action.payload)) {
        return {
          ...state,
          selectedAttributeOptions: state.selectedAttributeOptions.filter(
            (item) => item !== action.payload
          ),
        };
      }

      return {
        ...state,
        selectedAttributeOptions: [
          ...state.selectedAttributeOptions,
          action.payload,
        ],
      };

    case "SET_ATTRIBUTES_FROM_MEASUREMENTS":
      return {
        ...state,
        selectedAttribute: "",
        selectedAttributeOptions: action.payload,
      };

    case "SET_CURRENT_PAGE":
      return {
        ...state,
        currentPage: action.payload,
      };
    case "SET_SORT_BY":
      return {
        ...state,
        sortBy: action.payload,
      };
    case "SET_ORDER_BY":
      return {
        ...state,
        orderBy: action.payload,
      };
    default:
      return state;
  }
}
