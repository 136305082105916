import React from "react";
import { Box, Grid, Hidden, Typography, makeStyles } from "@material-ui/core";

import { Store } from "../../../store";
import MFPButton from "../../../components/Button";
import FootLengthImg from "../../../images/foot-length.jpg";
import FootWidthImg from "../../../images/foot-width.jpg";

import SaveButton from "../SaveButton";
import Foot from "./Foot";

import { isNumber } from "../../../helpers/isNumber";

const useStyles = makeStyles((theme) => ({
  button: {
    marginBottom: theme.spacing(1),
  },
}));

const genderButtons = [
  { label: "Men", id: "men" },
  { label: "Women", id: "women" },
  { label: "Kids", id: "kids" },
];

const footMeasurementBounds: {
  [key: string]: any;
} = {
  men: {
    length: {
      min: 21.15,
      max: 32.39,
    },
    width: {
      min: 4.9,
      max: 13.79,
    },
  },
  women: {
    length: {
      min: 21.15,
      max: 32.39,
    },
    width: {
      min: 4.9,
      max: 13.79,
    },
  },
  kids: {
    length: {
      min: 16.9,
      max: 23.79,
    },
  },
};

interface ErrorsState {
  [key: string]: string;
}

const FootSize: React.SFC<{ handleSave: (params: any) => void }> = ({
  handleSave,
}) => {
  const classes = useStyles();
  const { dispatch, state } = React.useContext(Store);
  const { footSize } = state;
  const [unsavedState, setUnsavedState] = React.useState(footSize);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [errors, setErrors] = React.useState<ErrorsState>({});

  const { gender, footWidth, footLength } = unsavedState;

  const checkForError = (dimension: string, value: string) => {
    if (!value) {
      return "Please enter a value";
    }

    if (!isNumber(value)) {
      return "Numbers only";
    }

    if (value < footMeasurementBounds[gender][dimension].min) {
      return `${dimension} is too small`;
    }

    if (value > footMeasurementBounds[gender][dimension].max) {
      return `${dimension} is too big`;
    }

    return "";
  };

  const onSave = () => {
    dispatch({
      type: "SET_FOOT_SIZE",
      payload: unsavedState,
    });

    let newErrors: { [key: string]: string } = {};
    let lengthError = checkForError("length", unsavedState.footLength);
    let widthError =
      gender === "kids" ? "" : checkForError("width", unsavedState.footWidth);
    if (lengthError) {
      newErrors.length = lengthError;
    }
    if (widthError) {
      newErrors.width = widthError;
    }

    setErrors(newErrors);
    setIsSubmitting(true);
  };

  React.useEffect(() => {
    if (
      isSubmitting &&
      gender &&
      footLength &&
      (footWidth || gender === 'kids') &&
      Object.keys(errors).length === 0
    ) {
      let params = `gender=${gender}&length=${footLength}&unit=us`;
      if (gender !== "kids") {
        params += `&width=${footWidth}`;
      }
      handleSave(params);
      setIsSubmitting(false);
    }
  }, [errors, isSubmitting]);

  const getGenderButton = ({ label, id }: { label: string; id: string }) => (
    <MFPButton
      selected={unsavedState.gender === id}
      className={classes.button}
      onClick={() =>
        setUnsavedState((prevState: any) => ({ ...prevState, gender: id }))
      }
      fullWidth
    >
      {label}
    </MFPButton>
  );

  return (
    <>
      <Box pb={2} textAlign="center">
        <SaveButton title={<>Save Size &amp; Exit</>} onClick={onSave} />
      </Box>

      <Box pb={2}>
        <Grid container justify="center" spacing={1}>
          {genderButtons.map(({ label, id }) => (
            <Grid item xs={4} sm={3} md={2} lg={1} key={id}>
              {getGenderButton({ label, id })}
            </Grid>
          ))}
        </Grid>
      </Box>

      <Hidden smUp>
        <Box mb={1}>
          <Typography>
            <b>Foot - Length &amp; Width</b>
          </Typography>
          <Typography variant="body2" gutterBottom>
            Stand bare foot on a sheet of paper
            <br />
            Draw 2 lines at LONGEST or WIDEST part of foot
            <br />
            Measure in CENTIMETERS
          </Typography>
        </Box>
      </Hidden>

      <Box mb={4}>
        <Grid container spacing={4} justify="center">
          <Foot
            error={errors.length}
            image={FootLengthImg}
            instructionsType="LONGEST"
            isSubmitting={isSubmitting}
            onChange={(footLength) =>
              setUnsavedState((prevState: any) => ({
                ...prevState,
                footLength,
              }))
            }
            title="Length"
            value={unsavedState.footLength}
          />

          {unsavedState.gender !== "kids" && (
            <Foot
              error={errors.width}
              image={FootWidthImg}
              instructionsType="WIDEST"
              isSubmitting={isSubmitting}
              onChange={(footWidth) =>
                setUnsavedState((prevState: any) => ({
                  ...prevState,
                  footWidth,
                }))
              }
              title="Width"
              value={unsavedState.footWidth}
            />
          )}
        </Grid>
      </Box>

      <Box py={0}>
        <SaveButton title={<>Save Size &amp; Exit</>} onClick={onSave} />
      </Box>
    </>
  );
};

export default FootSize;
