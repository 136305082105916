import React from "react";
import { Box, Button, Grid, Hidden, makeStyles } from "@material-ui/core";

import { Store } from "../../../store";

import { usSizes, euroSizes } from "../../../data/sizes";
import { widths } from "../../../data/widths";

import Fieldset from "../../../components/Fieldset";
import SaveButton from "../SaveButton";

import Column from "../Column";
import ColumnButton from "../ColumnButton";

const useStyles = makeStyles((theme) => ({
  setSizeFormat: {
    position: "absolute",
    right: 0,
    textDecoration: "underline",
    textTransform: "capitalize",
    top: "-2px",
    fontSize: "0.5875rem",
    [theme.breakpoints.up("lg")]: {
      top: "-28px",
    },
    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
  },
}));

const getSizes = (gender: string, format: string) => {
  if (format === "euro") {
    return euroSizes[gender];
  }
  return usSizes[gender];
};

const genders: { [key: string]: any } = {
  men: { label: "Men" },
  women: { label: "Women" },
  kids: { label: "Kids" },
};

const usePrevious = <T extends {}>(value: T): T | undefined => {
  const ref = React.useRef<T>();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const SneakerSize: React.SFC<{
  handleSave: (params: any) => void;
}> = ({ handleSave }) => {
  const classes = useStyles();
  const { dispatch, state } = React.useContext(Store);
  const { sneakerSize } = state;
  const prevValues = usePrevious({
    gender: sneakerSize.gender,
    sizeFormat: sneakerSize.sizeFormat,
  });
  const [unsavedState, setUnsavedState] = React.useState(sneakerSize);

  const { gender, size, sizeFormat, width } = unsavedState;

  const onSave = () => {
    dispatch({
      type: "SET_SNEAKER_SIZE",
      payload: unsavedState,
    });
    if (gender) {
      handleSave(
        `gender=${gender}&size=${size}&width=${width}&unit=${sizeFormat}`
      );
    }
  };

  const Measurements = () => (
    <>
      {genders[gender].label}
      <Box component="span" px={1}>
        &middot;
      </Box>
      {size}
      {gender !== "kids" && (
        <>
          <Box component="span" px={1}>
            &middot;
          </Box>
          {widths[width].label}
        </>
      )}
    </>
  );

  React.useEffect(() => {
    if (prevValues) {
      if (
        prevValues.gender !== gender ||
        prevValues.sizeFormat !== sizeFormat
      ) {
        // process here

        const firstSize = getSizes(gender, sizeFormat)[0];
        setUnsavedState((prevState: any) => ({
          ...prevState,
          size: firstSize,
        }));
      }
    }
  }, [gender, sizeFormat]);

  const getStepsLength = () => (gender === "kids" ? 2 : 3);

  return (
    <>
      <Box pb={2} textAlign="center">
        <SaveButton title="Save Size & Exit" onClick={onSave} />
      </Box>

      <Fieldset maxWidth={540} legend={<Measurements />}>
        <Button
          className={classes.setSizeFormat}
          onClick={() =>
            setUnsavedState((prevState: any) => ({
              ...prevState,
              sizeFormat: sizeFormat === "us" ? "euro" : "us",
            }))
          }
          size="small"
        >
          Use {sizeFormat === "us" ? "Euro" : "U.S."} Sizing
        </Button>
        <Grid container spacing={2} justify="center">
          <Column
            title={
              <>
                Gender <Hidden smUp>(step 1/{getStepsLength()})</Hidden>
              </>
            }
          >
            {Object.keys(genders).map((option) => (
              <ColumnButton
                key={option}
                selected={gender === option}
                onClick={() =>
                  setUnsavedState((prevState: any) => ({
                    ...prevState,
                    gender: option,
                  }))
                }
              >
                {genders[option].label}
              </ColumnButton>
            ))}
          </Column>
          <Column
            title={
              <b>
                Size - {sizeFormat === "us" ? "U.S." : "Euro"}{" "}
                <Hidden smUp>(step 2/{getStepsLength()})</Hidden>
              </b>
            }
          >
            {getSizes(gender, sizeFormat).map((option: number) => (
              <ColumnButton
                key={option}
                selected={size === option}
                onClick={() =>
                  setUnsavedState((prevState: any) => ({
                    ...prevState,
                    size: option,
                  }))
                }
              >
                {option}
              </ColumnButton>
            ))}
          </Column>

          {gender !== "kids" && (
            <Column
              title={
                <>
                  Width <Hidden smUp>(step 3/{getStepsLength()})</Hidden>
                </>
              }
            >
              {Object.keys(widths).map((option) => (
                <ColumnButton
                  key={option}
                  selected={width === option}
                  onClick={() =>
                    setUnsavedState((prevState: any) => ({
                      ...prevState,
                      width: option,
                    }))
                  }
                >
                  {widths[option].label}
                </ColumnButton>
              ))}
            </Column>
          )}
        </Grid>

        <Box mt={4} mb={1.5}>
          <Measurements />
        </Box>

        <SaveButton title="Save Size & Exit" onClick={onSave} />
      </Fieldset>
    </>
  );
};

export default SneakerSize;
