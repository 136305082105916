export const sneakerSizeInitialData = {
  gender: "men",
  size: 3,
  sizeFormat: "us",
  width: "narrow",
};

export function sneakerSizeReducer(state, action) {
  switch (action.type) {
    case "SET_SNEAKER_SIZE":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
